const handleInView = (element, threshold = 0, callback) => {
    if (!element) return () => { }
    let prevScrollPos = window.scrollY
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const currentScrollPos = window.scrollY
            const isScrollingDown = currentScrollPos > prevScrollPos
            if (entry.isIntersecting) {
                callback(entry.target, true, isScrollingDown)
            } else {
                callback(entry.target, false, isScrollingDown)
            }
        })
        prevScrollPos = window.scrollY
    }, { threshold })

    observer.observe(element)

    return () => {
        observer.unobserve(element)
    }
}

export default handleInView