import './app.scss'
import React, {  useState, useEffect } from 'react';
import Hero from './components/Hero';
import SwitchHybrids from './components/SwitchHybrids';
import mobileRotateImg from './assets/mobile-rotation.svg';
import DriveToHyspec from './components/DriveToHyspec';
import HyspecArrival from './components/HyspecArrival';
import HyspecPortfolio from './components/HyspecPortfolio';
import PartneringExcellence from './components/PartneringExcellence';
import UnlockHypsec from './components/UnlockHypsec';
import VideoComponent from './components/VideoComponent';
import Menu from './components/Menu';
// import Footer from './components/Footer';
import portraitArrowSVG from './assets/texture-bg.png';
import castrolLogo from './assets/castrol_logo.svg';
import LanguageSelector from "./components/LanguageSelector";

import { checkIsMobile, checkOrientation, getLanguageCode, getTranslations } from './utils/utils'
// import data from "./locales/en-US.json"
import { DataContext } from './utils/dataContext';

function App() {

  const jsonData = getTranslations(getLanguageCode())
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  const [isLanguageSelectorVisible, setLanguageSelectorVisibility] = useState(true);


  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  if (isTouchDevice) {
    document.body.classList.add("touch-device")
  }
  //const mobileDeviceWidth = 400

  const handleOrientationChange = () => {
    setIsLandscape(!(checkIsMobile() && checkOrientation() === 'portrait'));
    //setIsLandscape(window.innerWidth > mobileDeviceWidth);
  };



  const toggleLanguageSelector = () => {
    setLanguageSelectorVisibility(!isLanguageSelectorVisible);
  };

  useEffect(() => {
    document.querySelector('html').classList.add(getLanguageCode() || 'en-US')
    handleOrientationChange(); // Initial check
    window.addEventListener('resize', handleOrientationChange)
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [jsonData])

  

  return (
    <div className="App">
      {isLandscape ? (
        <>
        <DataContext.Provider value={jsonData}>

          <div className='js-fake-ele' style={{ display: 'none' }}></div>
          {isLanguageSelectorVisible && <LanguageSelector />}
          <Menu />
          <Hero toggleLanguageSelector={toggleLanguageSelector} />
          <SwitchHybrids />
          <DriveToHyspec />
          <HyspecArrival />
          <HyspecPortfolio />
          <PartneringExcellence />
          <UnlockHypsec />
          <VideoComponent />
          {/* <Footer /> */}
        </DataContext.Provider>
        </>
      ) : (
        // Display message encouraging users to switch to landscape mode

        <div className='portrait-mode-lock'>
          <img src={castrolLogo} alt="bg" className='portrait-lock-castrol-Logo' />
          <img src={portraitArrowSVG} alt="bg" className='portrait-mode-lock-bg' />
          <div className='landscape-warning-msg-container'>
            <img src={mobileRotateImg} alt="Mobile rotation Symbol" />
            <p>Please rotate your device to landscape mode for an optimal viewing experience.</p>
          </div>
        </div>

      )}

    </div>
  );
}

export default App;
