import React, { useEffect, useRef, useState } from 'react'
import handleInView from '../utils/handleInView'
import { toggleMenu as showMenu } from '../utils/utils'
// import { useTranslation } from 'react-i18next'
// import {DataContext} from "../utils/dataContext"
// import { useFindValueByKey } from "../utils/useFindByKey";
// import { getLanguageCode } from '../utils/utils'

const Menu = () => {
  const menuRef = useRef(null)
  const sections = useRef([])
  // const [currentLang, setCurrentLang] = useState('')
  // const { i18n } = useTranslation()
  const [activeSection, setActiveSection] = useState('')
  const [collapsed] = useState(false)

  // const jsonObject = useContext(DataContext);
  // const [findByKey] = useFindValueByKey(jsonObject)

  useEffect(() => {

    const stopObservers = []
    sections.current = Array.from(document.querySelectorAll('.section .js-section-heading'))
    sections.current.forEach((element, index) => {
      const stopObserving = handleInView(element, 0.2, (entryTargetEle, inView) => {
        if (inView) {
          showMenu(true)
          const id = +entryTargetEle.getAttribute('id')
          setActiveSection(id + "")
        }
      })
      stopObservers.push(stopObserving)
    })

    // function handleLanguageChange(newLang) {
    //   setCurrentLang(newLang)
    // }
    // i18n.on('languageChanged', handleLanguageChange)

    return () => {
      stopObservers.forEach(unobserve => unobserve())
      // i18n.off('languageChanged', handleLanguageChange);
    }
  }, [])

  const handleMenuItemClick = (section) => {

    if (!section) return
    const id = section.getAttribute("id")
    const scrollOffset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
    const targetOffset = section.getBoundingClientRect().top + scrollOffset

    window.scrollTo({
      top: targetOffset - 150,
      behavior: 'smooth'
    })

    setActiveSection(id)
  }

  const toggleMenu = () => {

  }


  return (

    <nav className={`menu ${collapsed ? 'closed' : 'opened'}`} onClick={toggleMenu} ref={menuRef}>
      <div className={`item-wrapper`}>
        <ul className="menu-list" >
          {sections.current.map((section, index) => (
            <li
              key={section.getAttribute('id')}
              className={`menu-item ${activeSection === section.getAttribute('id') ? 'active' : ''}`}
              onClick={() => handleMenuItemClick(section)}
            >
              <>
                <span className='list-number'>{index + 1}.</span>
                <span className='list-text'>{section.getAttribute('data-title')}</span>
              </>

            </li>
          ))}
        </ul>
      </div>
    </nav>

  )
}

export default Menu