import React, { useRef ,useEffect, useContext} from 'react'
import { f1Car, f1CarShadow, rallyCar, carWithBackground, car2Background } from './assets'
import Modal from './Modal'
import { ReactComponent as LineSVG } from '../assets/line9.svg';
import { ReactComponent as UPPERPathSVG } from '../assets/line8.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TextAnimation from './TextAnimation';
import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";


const PartneringExcellence = () => {
    gsap.registerPlugin(ScrollTrigger)

    const f1CarParaRef = useRef('')
    const rallyCarContainerRef = useRef('')
    const rallyCarRef = useRef('')
    const rallyCarSmokeBgRef = useRef('')
    const rallyCarPara1Ref = useRef('')
    const rallyCarPara2Ref = useRef('')
    const carContainerRef = useRef('')
    const upperPathRef = useRef('')
    const downPathRef =  useRef('')

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    useEffect(() => {
        
        const master = gsap.timeline({
            scrollTrigger: {
                trigger: carContainerRef.current,
                start: '-40% 40%',
                end: 'center center',
                performance: true,
                scrub: 5
            }
        })

        const rallyCarTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: rallyCarContainerRef.current,
                start: '32% center',
                end: 'center center',
                scrub: 5,
                markers:false,
                performance: true,
                onEnter: () => {
                    rallyCarContainerRef.current.classList.add("rally-car-animation-fade-in")
                    setTimeout(()=>{
                        rallyCarContainerRef.current.classList.add("rally-car-animation-fade-out")
                    },1400)
                },
                onLeaveBack: () => {
                    rallyCarContainerRef.current.classList.remove("rally-car-animation-fade-in", "rally-car-animation-fade-out")
                }
            }
        })
        const f1CarTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: carContainerRef.current,
                start: '-10% center',
                end: 'center center',
                scrub: 5,
                markers:false,
                performance: true,
                onEnter: () => {
                    carContainerRef.current.classList.add("f1-car-animation-trigger-fade-in")
                
                    setTimeout(()=>{
                        carContainerRef.current.classList.add("f1-car-animation-trigger-fade-out")
                    },1000)
                    
                },
                onLeaveBack: () => {
                    carContainerRef.current.classList.remove("f1-car-animation-trigger-fade-in", "f1-car-animation-trigger-fade-out")
                }
            }
        })
        const upperPathTl = gsap.timeline({
            scrollTrigger: {
                trigger: upperPathRef.current,
                start: '-10% center',
                end: '-15% center',
                scrub: 2.5,
                performance: true,
                markers:false
            }
        })
        upperPathTl.to("#eZdfc38M6TJ37_to",{
            x: -857.039823,
            y: 482.0905,
            duration: 2.5
        })
        const downPathTl = gsap.timeline({
            scrollTrigger: {
                trigger: downPathRef.current,
                start: '0% center',
                end: '10% center',
                scrub: 5,
                performance: true,
                markers:false
            }
        })
        downPathTl.to("#ez6va4oxBHD37_to",{
            x: 1350.12,
            y: 1493.585184,
            duration: 5
        })

        return () => {
            if (master) {
                master.kill();
                rallyCarTimeline.kill();
                f1CarTimeline.kill();
                upperPathTl.kill()
                downPathTl.kill()
            }
        }
    }, [])

    return (
        <div className='partnering-excellence section OneLinkTx' id='5' data-title={findByKey("PartneringExcellence.menu-title")}>
            <div className='container'>
                <TextAnimation  animationType="fadeIn" duration={1} delay={0.3} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 175%"} end={"60% 135%"}>
                    <h2 className='section-heading js-section-heading OneLinkTx' id='5' data-title={findByKey('PartneringExcellence.menu-title')} dangerouslySetInnerHTML={{ __html: findByKey("PartneringExcellence.title") }}>
                    </h2>
                </TextAnimation>
                <TextAnimation className='text-content OneLinkTx' animationType="slideInY" duration={2} delay={2} ease={"power3.in"} start={"top 115%"} end={"bottom 80%"}>
                <p >
                        {findByKey('PartneringExcellence.body.para-1')}
                </p>
                    </TextAnimation>                
                <div className='upper-car-image' ref={carContainerRef}>
                    <UPPERPathSVG className='upperPath' ref={upperPathRef} />
                    <img className='car2-background'  src={car2Background} alt='' />
                    <img className='f1-car-img f1-car-shadow' src={f1CarShadow} alt=''/>
                    <div className='f1-car'>
                        <img className='f1-car-img f1-car1' src={f1Car} alt=''/>
                        <img className='f1-car-img f1-car2' src={f1Car} alt=''/>
                        <img className='f1-car-img f1-car3' src={f1Car} alt=''/>                      
                        <img className='f1-car-img f1-car4' src={f1Car} alt=''/>
                    </div>
                </div>
                <div className='right-para-p1'>
                    <TextAnimation animationType="fadeIn" duration={1} delay={0.5} ease={"power3.in"} start={"top 175%"} end={"bottom 95%"}>
                        <p id='f1CarPara' className='OneLinkTx' ref={f1CarParaRef} dangerouslySetInnerHTML={{ __html: findByKey("PartneringExcellence.body.para-2") }}>
                        </p>
                    </TextAnimation>
                </div>
                <div className='rally-card-wrapper' ref={rallyCarContainerRef}>
                    <img className='car-background' src={carWithBackground} alt='' ref={rallyCarSmokeBgRef} />
                    <div className='rally-car ' ref={rallyCarRef}>
                        <img className='rally-car-img rally-car1' src={rallyCar} alt=''/>
                        <img className='rally-car-img rally-car2' src={rallyCar} alt=''/>
                        <img className='rally-car-img rally-car3' src={rallyCar} alt=''/>
                        <img className='rally-car-img rally-car4' src={rallyCar} alt=''/>
                    </div>
                    <LineSVG className='downPath' ref={downPathRef}/>
                </div>
                <div className='right-para' >
                    <div className='right-upper-para' ref={rallyCarPara1Ref}>
                        <TextAnimation animationType="fadeIn" duration={1} delay={0.5} ease={"power3.in"} start={"top 125%"} end={"bottom 75%"}>
                            <p className='OneLinkTx'>
                                {findByKey('PartneringExcellence.body.para-3')}
                            </p>
                        </TextAnimation>
                    </div>
                    <div className='right-lower-para' ref={rallyCarPara2Ref}>
                        <TextAnimation animationType="fadeIn" duration={1.01} delay={0.5} ease={"power3.in"} start={"top 125%"} end={"bottom 75%"}>
                            <p className='OneLinkTx' dangerouslySetInnerHTML={{ __html: findByKey("PartneringExcellence.body.para-4") }}>
                            </p>
                        </TextAnimation>
                    </div>
                </div>
                <div>
                    <Modal id={3} />
                </div>
            </div>
        </div>
    )
}

export default PartneringExcellence