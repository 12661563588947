import React, { useState, useEffect, useRef, useContext } from 'react';
// import diagram from '../../assets/diagram.svg';
import MainPillers from './MainPillers';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";
import { getLanguageCode } from '../../utils/utils';


const FAQSection = () => {


    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)
    const languageCode = getLanguageCode();

    gsap.registerPlugin(ScrollTrigger);

    const [, setActiveCard] = useState(0);
    const questionsContainerRef = useRef('')
    const tabRef = useRef('');

    const cards = [
        {
            id: 0,
            title: findByKey("FaqSection.body.0.title"),
            content:
                <>
                    <div className='graphic-container'>
                        {/* <img src={diagram} alt='diagram' /> */}
                        <img src={`./assets/${languageCode}/diagram.svg`} alt='diagram' />
                        <div className='diagram-desc' id='graphic-div-0'>
                            <p className='OneLinkTx' dangerouslySetInnerHTML={{ __html: findByKey('FaqSection.body.0.para-1') }}>
                            </p>
                        </div>
                    </div>
                    <div className='text-below'>
                        <p className='OneLinkTx'>{findByKey('FaqSection.body.0.para-2')}</p><p className='OneLinkTx'>{findByKey('FaqSection.body.0.para-3')}</p>
                    </div>
                </>
        },
        {
            id: 1,
            title: findByKey("FaqSection.body.1.title"),
            content: <div id="graphic-div-1">
                <p className='tab-two-para OneLinkTx'>{findByKey("FaqSection.body.1.para-1")}</p>
                <MainPillers />
            </div>

        }
    ];
    const totalCard = cards.length - 1
    useEffect(() => {
        const container = questionsContainerRef.current;
        const cardElement = container.querySelector('.js-card');
        const slides = container.querySelectorAll('.js-card .slide');
        const content1 = container.querySelector('.js-content-1');
        const content2 = container.querySelector('.js-content-2');
        const tab = container.querySelector('.js-card')
        const pinDelay = 0;
        let tween
        const pinTimeout = setTimeout(() => {
            tween = gsap.to(slides, {
                ease: 'linear',
                scrollTrigger: {
                    start: 'top 5%',
                    trigger: tab,
                    pin: true,
                    pinSpacing: false,
                    anticipatePin: 1,
                    performance: true,
                    scrub: 5,
                    end: () => '+=' + container.offsetHeight * 1,
                    onEnter: () => {
                        cardElement.classList.add('pinned');
                        slides[0].classList.add('active');
                        slides[1].classList.remove('active');
                    },
                    onLeaveBack: () => {
                        cardElement.classList.remove('pinned');
                        slides[0].classList.remove('active');
                        slides[1].classList.remove('active');
                    },
                    onEnterBack: () => {
                        cardElement.classList.add('pinned');
                        slides[0].classList.remove('active');
                        slides[1].classList.add('active');
                    },
                    onLeave: () => {
                        cardElement.classList.remove('pinned');
                        slides[0].classList.remove('active');
                        slides[1].classList.remove('active');
                    },
                    onUpdate: ({ progress, direction }) => {
                        const p = Math.round(progress * 100);
                        if (p <= 20 && direction === 1) {  // forward first section
                            slides[0].classList.add('active');
                            slides[1].classList.remove('active');
                        } else if (p <= 33 && direction === -1) { // reverse first section
                            slides[0].classList.add('active');
                            slides[1].classList.remove('active');
                        }
                        else if (p >= 37 && direction === 1) { // forward second section
                            slides[0].classList.remove('active');
                            slides[1].classList.add('active');
                        } else if (p === 100) {

                        }
                    },
                },

            });

            tween.scrollTrigger.refresh()
        }, pinDelay)

        const tween2 = gsap.to(content1, {
            ease: 'linear',
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
                start: '-5% center',
                end: "top center",
                performance: true,
                trigger: '.js-card-content-s2',
                scrub: 5,
                markers: false
            }
        })
        tween2.scrollTrigger.refresh()

        const tween3 = gsap.to(content2, {
            ease: 'linear',
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
                start: '42% center',
                end: "46% center",
                performance: true,
                trigger: '.js-card-content-s2',
                scrub: 5,
                markers: false
            }
        })

        tween3.scrollTrigger.refresh()


        return () => {
            clearTimeout(pinTimeout)
            if (tween) tween.kill()
            if (tween2) tween2.kill()
            if (tween3) tween3.kill()
        };
    }, [totalCard]);



    const handleCardClick = (index) => {
        setActiveCard(() => index);
    };

    return (
        <>
            <div className='questions-container' ref={questionsContainerRef}>
                <div className='card js-card' ref={tabRef}>
                    {cards.map((card, index) => (
                        <div key={card.id} className="card-slider">
                            <div
                                className={`slide`}
                                onClick={() => handleCardClick(index)}
                            >
                                <p className='card-title' >{card.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className=" js-card-content-s2" style={{}}>
                    <div className='js-content-1' style={{ opacity: 0 }}>
                        <section>
                            {cards[0].content}
                        </section>
                    </div>
                    <div className='js-content-2' style={{ opacity: 0 }}>
                        <section style={{}}>
                            {cards[1].content}
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQSection