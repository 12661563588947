import React, { useContext, useEffect } from 'react';
import curvedLineSvg from '../assets/divider.svg'
import CarSlider from './SwitchHybrids/CarSlider';
import Electrification from './SwitchHybrids/Electrification';
import SeparateComps from './SwitchHybrids/SeparateComps';
import HybridTypes from './SwitchHybrids/HybridTypes';
import Standards from './SwitchHybrids/Standards';
import Modal from './Modal';
import { ReactComponent as LineSVG } from '../assets/line1.svg'
import { ReactComponent as ArrowSVG } from '../assets/texture-bg.svg'

import { gsap } from 'gsap';

import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";

const SwitchHybrids = () => {

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    useEffect(() => {

        const texts = gsap.utils.toArray(document.querySelectorAll('.js-animation-h'))
        gsap.set(texts, { opacity: 0, duration: 0, delay: 0 })
        let tl
        const headingText = gsap.timeline({
            scrollTrigger: {
                trigger: ".js-section-1",
                start: "-1.5% center",
                end: "-1.5% center",
                scrub: 2,
                performance: true,
                markers: false,
                onEnter: () => {
                    tl = gsap.timeline({})
                    tl.to(texts, { opacity: 1, stagger: 0.1, duration: 0.2, delay: 0, ease: "power3.in" })
                },
                onLeaveBack: () => {
                    if (tl) tl.revert()
                }
            }
        })
        // path animation
        const pathAnimationTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line1-svg',
                start: '38% center',
                end: '45% center',
                scrub: 5,
                performance: true,
                markers: false
            }
        })
        pathAnimationTl.to('#eeylcQWvnkE5_to', {
            x: 847.836548,
            y: 945.777679,
            duration: 2
        })
        // arrow animation
        let tl2
        const arrowTL1 = gsap.timeline({
            scrollTrigger: {
                trigger: '.electrification',
                start: '22% center',
                end: '26% center',
                scrub: 5,
                performance: true,
                markers: false,
                onEnter: () => {
                    tl2 = gsap.timeline({})
                    tl2.to('.js-arrow-1 #e8utjXfyGxH5_to', {
                        x: 697.72699,
                        y: 558.94696,
                        delay: 0.1,
                        ease: "Sine.easeOut",
                        duration: 2
                    })
                },
                onLeaveBack: () => {
                    if (tl2) {
                        tl2.revert()
                    }
                }
            }
        })
        return () => {
            if (headingText) {
                headingText.revert()
                headingText.kill()
            }
            if (tl) {
                tl.revert()
                tl.kill()
            }
            if (arrowTL1) {
                arrowTL1.revert()
                arrowTL1.kill()
            }
            if (pathAnimationTl) {
                pathAnimationTl.kill()
            }
        };
    }, []);

    return (
        <div className='switch-hybrids section js-section-1 OneLinkTx' id='1' data-title={findByKey("SwitchHybrids.menu-title")}>
            <div className='container' >
                <div className='inner-container-upper'>
                <div className="js-fake js-animation-h" style={{ display: 'none' }}></div>
                <h2 className='section-heading js-section-heading js-animation-h OneLinkTx' id='1' data-title={findByKey("SwitchHybrids.menu-title")} style={{ opacity: 0 }}>{findByKey("SwitchHybrids.title")}</h2>
                <p className='para js-animation-h OneLinkTx' style={{ opacity: 0 }}>{findByKey("SwitchHybrids.body.para-1")}</p>
                <p className='para1 OneLinkTx'>
                    {[findByKey("SwitchHybrids.body.para-2.0"), findByKey("SwitchHybrids.body.para-2.1"), <strong>{findByKey("SwitchHybrids.body.para-2.2")}</strong>, ""].map((word, index) => (
                        <span className='js-animation-h' style={{ opacity: 0 }} key={index}>
                            {word}&nbsp;
                        </span>
                    ))}</p>

                <LineSVG className='line-svg js-line1-svg' />
                <CarSlider scale={1.2} duration={0.5} />
                <div className='electrification'>
                    <ArrowSVG className='js-arrow-1' />
                    <div className='elf-content'>
                        <Electrification />
                    </div>
                </div>
                <img className='section-divider' src={curvedLineSvg} alt='' />
                <SeparateComps />
                </div>
                <div className='inner-container-bottom'>
                <HybridTypes />
                <Standards />
                <Modal id={0} />
                </div>
            </div>
        </div>
    )
}

export default SwitchHybrids