import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import close from '../assets/close.png'
import { gsap } from 'gsap'

const ShowModal = ({ closeModal, children, className, id }) => {
  useEffect(() => {
    const tl = gsap.timeline({})
    tl.to('.modal-container', {opacity: 1, duration: 1},"modal")
    tl.to('.modal-wrapper', {opacity: 1, duration: 1},"modal")
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'scroll';
    }
  }, [])

  return ReactDOM.createPortal(
    <>
        <div className='modal-wrapper' onClick={closeModal} style={{opacity:0}}></div>
        <div className={`modal-container ${className}`} style={{opacity:0}}>
          <div className='close'><img src={close} alt='' onClick={closeModal} /></div>
          {children}
        </div>
    </>,
    document.querySelector('.myModelView')
  )

}

export default ShowModal