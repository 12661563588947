import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import videoSrc from '../assets/dropLet.mp4'
import videoPoster from '../assets/dropLet-poster.jpg'

const VideoScrollAnimation = ({ className }) => {
  const videoRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current
    const container = containerRef.current
    let duration
    gsap.registerPlugin(ScrollTrigger)
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: '30% center',
        end: '90% center',
        scrub: 5,
        markers: false,
        performance: true,
        onEnter: () => {
          video.play().then(() => { console.log('play');}).catch(error => { console.error("Error playing video:", error); });
          //gsap.to(video, { currentTime: duration || 5, duration: duration || 5 })
        },
        onLeaveBack: () => {
          video.pause()
          video.currentTime = 0
          //gsap.to(video, { currentTime: 0, duration: 0.1 })
        }
      }
    })

    const loadedMetadataHandler = () => {
      duration = video.duration || duration || 5
    }

    video.addEventListener('loadedmetadata', loadedMetadataHandler)

    return () => {
      tl.revert()
      tl.kill()
      video.removeEventListener('loadedmetadata', loadedMetadataHandler)
    }
  }, [])

  return (
    <div className={'video-container ' + className} ref={containerRef}>
      <video
        ref={videoRef}
        src={videoSrc}
        poster={videoPoster}
        playsInline={true}
        preload="auto"
        muted="muted"
        className="video-background"
      ></video>
    </div>
  )
}

export default VideoScrollAnimation
