import { useRef, useCallback } from 'react';

export function useFindValueByKey(initialObject) {
 const object = useRef(initialObject);

 const findValueByKey = useCallback((key) => {
    return key.split('.').reduce((obj, k) => (obj && obj[k] !== 'undefined') ? obj[k] : undefined, object.current);
 }, []);

 return [findValueByKey];
}
