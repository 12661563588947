import React, { useState, useEffect, useRef, useContext } from 'react'
import grayCarImg from '../../assets/gray_car.svg'
import greenCar from '../../assets/green_car.png'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";

gsap.registerPlugin(ScrollTrigger)

const CarSlider = ({ scale = 1, duration = 0.5, initialPosition = -200}) => {
    const [activeCard, setActiveCard] = useState(-1);
    const cardListRef = useRef(null)
    const cardContainerRef = useRef(null)

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    const cards = [
        { id: 0, imgSrc: grayCarImg },
        { id: 1, imgSrc: grayCarImg },
        { id: 2, imgSrc: grayCarImg },
        { id: 3, imgSrc: grayCarImg }
    ];


    useEffect(() => {
        const cardList = Array.from(cardListRef.current.querySelectorAll('.card-slider'));
        gsap.set(cardList, { opacity: 0 })
       let tl
        const master = gsap.timeline({
            scrollTrigger: {
                trigger: cardContainerRef.current,
                start: '-40% center',
                end: 'bottom center',
                scrub: 5,
                performance: true,
                markers: false,
                onEnter: () => {
                    tl = gsap.timeline()
                    playTimelineSequence(tl)
                },
                onLeaveBack: () => {
                    reverseTimeline(tl)
                }
            }
        })
         
        function playTimelineSequence(tl) {
           
            tl.to(cardList, { opacity: 1, duration: 0.5, stagger: 0.6, ease: "linear"})
            tl.to(cardList[1], {
                duration: 0, stagger: 1,delay:0,scale: 1, className: "card-slider active", onComplete: () => {
                    setActiveCard(1)
                }, onReverseComplete: () => {
                    setActiveCard(-1)
                }
            })
            tl.to('.js-card-content', { opacity: 1, stagger: 1, duration: 1}, "text")
            tl.to('.js-anim-text-1', { opacity: 1, duration: 0.2 }, "text")
            // chain animation sequence
            // tl.to(".js-anim-text", { opacity: 1, delay: 0, duration: 0.5, stagger: 0 })
            // tl.to(".js-engine-spark", {
            //   transform: 'translate(0, 0)', opacity: 1, stagger: 0, duration: 1.5, ease: "power3.in"
            // })
            // tl.to(".js-mask", { opacity: 1, stagger: 0, duration: 1, ease: "power3.in" })
            // tl.to(".js-spark-engine-title", { opacity: 1, stagger: 0, duration: 0.5, ease: "liner" })
    
        }

        function reverseTimeline() {
            setActiveCard(-1)
            tl.revert()
            tl.kill()
            document.querySelector('.js-card-content').style.opacity = 0
            cardList.forEach(card => {
                card.removeAttribute('style')
                card.classList.remove('active')
                card.style.opacity = 0
                card.style.scale = 1
            });
        }

        return () => {
            if (master) {
                master.kill();
            }
            if (tl) {
                tl.kill()
            }
        }
    }, [duration,initialPosition,scale])

    return (
        <div className='card-container' id='card-container' ref={cardContainerRef}>
            {/* <div className='card1'></div> */}
            <div className='card' ref={cardListRef}>
                {cards.map((card, index) => (
                    <div key={card.id} className="card-slider">
                        <div
                            className={`card-image `}
                        >
                            <img src={index !== activeCard ? card.imgSrc : greenCar} alt='' />
                        </div>
                    </div>
                ))}
                <div className="card-content">
                    <div className="js-card-content" style={{ opacity: 0 }}><p dangerouslySetInnerHTML={{ __html:findByKey("CarSlider.body.para-1") }} className='OneLinkTx'/></div>
                    <div><p className='gray-text js-anim-text-1 OneLinkTx' style={{ opacity: 0 }} dangerouslySetInnerHTML={{ __html:findByKey("CarSlider.body.para-2") }} /></div>
                </div>
            </div>
        </div >
    );
};

export default CarSlider;
