import React, { useContext } from 'react';
import Modal from './Modal';
import TextAnimation from './TextAnimation';
import VideoScrollAnimation from './VideoScrollAnimation';
import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";

const HyspecArrival = () => {

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    return (
        <div className='hyspec-arrival section OneLinkTx' id='3' data-title={findByKey("HyspecArrival.menu-title")}>
            <div className='container'>
                <TextAnimation animationType="fadeIn" duration={1} delay={1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 5%"} end={"bottom center"}>
                    <h2 className='js-section-heading section-3 OneLinkTx' id='3' data-title={findByKey("HyspecArrival.menu-title")}>
                        {findByKey("HyspecArrival.title")}
                    </h2>
                </TextAnimation>
                <div className='hyspecBg'>
                    <VideoScrollAnimation className='bg-image' />
                    <div className='section-three-image'>
                        <div className='section3-info'>
                            <TextAnimation className='text' animationType="fadeIn" duration={1} delay={1.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 92%"} end={"bottom 60%"}>
                                <p className='OneLinkTx'>{findByKey("HyspecArrival.para")} </p>
                            </TextAnimation>

                            <div className='grid js-grid'>
                                <div className='grid-item' id='grid-item-1'>
                                    <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={0.1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"-15% center"} end={"-15% center"}  >
                                        <p className='title OneLinkTx'>{findByKey("HyspecArrival.body.0.title")}</p>
                                    </TextAnimation>
                                    <div className='description'>
                                        <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={0.7} ease={"power3.in"} initialState={{ opacity: 0 }} start={"-16% center"} end={"-14% center"}>
                                            <p className='OneLinkTx'>{findByKey("HyspecArrival.body.0.para-1")}</p>
                                        </TextAnimation>
                                    </div>
                                </div>
                                <div className='grid-item' id='grid-item-2'>
                                    <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"5% center"} end={"6% center"} >
                                        <p className='title OneLinkTx' dangerouslySetInnerHTML={{ __html: findByKey("HyspecArrival.body.1.title") }}></p>
                                    </TextAnimation>
                                    <div className='description'>
                                        <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={1.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"10% center"} end={"8% center"} >
                                            <p className='OneLinkTx'>{findByKey("HyspecArrival.body.1.para-1")}</p><br /><p className='OneLinkTx'>{findByKey("HyspecArrival.body.1.para-2")}</p>
                                        </TextAnimation>

                                    </div>
                                </div>
                                <div className='grid-item' id='grid-item-3'>
                                    <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={0.1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"45% center"} end={"45% center"} >
                                        <p className='title OneLinkTx'>{findByKey("HyspecArrival.body.2.title")}</p>
                                    </TextAnimation>
                                    <div className='description'>
                                        <TextAnimation scrollElement=".js-grid" animationType="fadeIn" duration={0.3} delay={1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"45% center"} end={"45% center"} >
                                            <p className='OneLinkTx'>{findByKey("HyspecArrival.body.2.para-1")}</p>
                                        </TextAnimation>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal id={1} textAnimation={true} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HyspecArrival