import React, { useState, useRef, useContext } from 'react';
import engine from '../../assets/engine.svg';
import waterDrop from '../../assets/waterdrop.svg';
import meter from '../../assets/meter.svg';
import line from '../../assets/hierarchy-line.svg';
import playBtn from "../../assets/play-btn-new.svg";
import videoPoster from '../../assets/video-poster.png'
import videoPoster2 from '../../assets/video-poster2.png'
import videoPoster3 from '../../assets/video-poster2.png'

import { ReactComponent as Arrow } from '../../assets/arrow_back_ios_new_black_24dp.svg'
import arrowShadow from '../../assets/arrow_back_ios_animation.svg'
import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";
import { contaminantsVideo, contaminantsVideoTH, efficiencyVideo, efficiencyVideoTH, intermittencyVideo, intermittencyVideoTH } from '../assets';
import { getLanguageCode } from '../../utils/utils';

const MainPillers = () => {

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    const [activeCard, setActiveCard] = useState(0);
    const [buttonVisible, setButtonVisible] = useState(true);
    const videoRef = useRef(null);

    const languageCode = getLanguageCode();

    const handleCardClick = (dir, type) => {
        if (type === 'icon') setActiveCard(dir)
        else {
            if (activeCard === 0 && dir === -1) return
            if (activeCard === 2 && dir === 1) return
            setActiveCard((prev) => {
                return prev + (1 * dir)
            });
        }
        setButtonVisible(true)
    };

    function playVideo() {
        const video = videoRef.current;
        if (!video) return
        if (video.paused) {
            video?.play().then(() => {
                console.log('play');
                setButtonVisible(false)
            }).catch(error => { console.error("Error playing video:", error); });
        } else {
            video?.pause();
            setButtonVisible(true)
        }
    }

    const handleButtonClick = (index) => {
        playVideo()
    };

    const cards = [
        {
            id: 0,
            imgSrc: waterDrop,
            title: findByKey("MainPillars.body.0.title"),
            content:
                <div id='card-0'>
                    <p className='HYSPEC-main-piller-para OneLinkTx'>{findByKey("MainPillars.body.0.content.para-1")}</p>
                    <div className='video-container'>
                        <p className='OneLinkTx'>{findByKey("Common.keyPressInstruction.instruction-1")} <span onClick={() => handleButtonClick(0)}>{findByKey("Common.keyPressInstruction.instruction-2")}</span> {findByKey("Common.keyPressInstruction.instruction-3")}</p>
                        <video
                            type='video/mp4'
                            ref={videoRef}
                            key={1}
                            poster={videoPoster}
                            controls
                            playsInline={true}
                            onPause={() => {setButtonVisible(false)}}
                            onPlay={() => {setButtonVisible(false)}}
                        >
                            <source
                                src={languageCode === 'th-TH' ? `${contaminantsVideoTH}` : `${contaminantsVideo}`}
                            />
                        </video>
                        {buttonVisible && (

                            <button
                                className="play-button"
                                onClick={() => handleButtonClick(0)}
                                key={2}
                            >
                                <img src={playBtn} alt='' />
                            </button>

                        )}
                    </div>
                </div>
        },
        {
            id: 1,
            imgSrc: engine,
            title: findByKey("MainPillars.body.1.title"),
            content: <div id='card-1'>
                <p className='HYSPEC-main-piller-para OneLinkTx'>{findByKey("MainPillars.body.1.content.para-1")}</p>
                <div className='video-container'>
                    <p id='card-para-1' className='OneLinkTx'>{findByKey("Common.keyPressInstruction.instruction-1")} <span onClick={() => handleButtonClick(1)} className='OneLinkTx'>{findByKey("Common.keyPressInstruction.instruction-2")}</span> {findByKey("Common.keyPressInstruction.instruction-3")}</p>
                    <video
                        type='video/mp4'
                        ref={videoRef}
                        key={3}
                        poster={videoPoster2}
                        controls
                        playsInline={true}
                        onPause={() => {setButtonVisible(false)}}
                        onPlay={() => {setButtonVisible(false)}}
                    >
                        <source
                            src={languageCode === 'th-TH' ? `${intermittencyVideoTH}` : `${intermittencyVideo}`}
                        />
                    </video>
                    {buttonVisible && (

                        <button
                            className="play-button"
                            onClick={() => handleButtonClick(1)}
                            key={4}
                        >
                            <img src={playBtn} alt='' />
                        </button>

                    )}
                </div>
            </div>
        },
        {
            id: 2,
            imgSrc: meter,
            title: findByKey("MainPillars.body.2.title"),
            content: <div id='card-2'>
                <p className='HYSPEC-main-piller-para OneLinkTx'>{findByKey("MainPillars.body.2.content.para-1")}</p>
                <div className='video-container'>
                    <p id='card-para-2' className='OneLinkTx'>{findByKey("Common.keyPressInstruction.instruction-1")} <span onClick={() => handleButtonClick(2)} className='OneLinkTx'>{findByKey("Common.keyPressInstruction.instruction-2")}</span> {findByKey("Common.keyPressInstruction.instruction-3")}</p>
                    <video className='section-two-video'
                        type='video/mp4'
                        ref={videoRef}
                        key={5}
                        poster={videoPoster3}
                        controls
                        playsInline={true}
                        onPause={() => {setButtonVisible(false)}}
                        onPlay={() => {setButtonVisible(false)}}
                    >
                        <source
                            src={languageCode === 'th-TH' ? `${efficiencyVideoTH}` : `${efficiencyVideo}`}
                        />
                    </video>
                    {buttonVisible && (

                        <button
                            className="play-button"
                            onClick={() => handleButtonClick(2)}
                            key={6}
                        >
                            <img src={playBtn} alt='' />
                        </button>

                    )}
                </div>
            </div>
        }
    ];

    return (

        <div className='main-pillars'>
            <div className='heading-container'>
                <p id='card-para-3' className='heading OneLinkTx'>{findByKey("MainPillars.title")}</p>
                <img src={line} alt='' />
            </div>

            <div className='cards'>
                <div className='cards-container'>
                    <div className={`previous-btn js-previous-btn ${activeCard > 0 && activeCard <= 2 ? 'active-btn' : ''}`} onClick={() => handleCardClick(-1)}><img className='previous-arrow-shadow' src={arrowShadow} alt="prev-arrow" /><Arrow className='previous-arrow' /></div>
                    {cards.map((card, index) => (
                        <div key={card.id} className={`card ${index === activeCard ? 'active' : ''}`}>
                            <div
                                className="card-image"
                            >
                                <img src={card.imgSrc} alt='' style={{ cursor: "pointer" }} onClick={() => handleCardClick(index, 'icon')} />
                            </div>
                            <div className='card-title'>
                                <h4 className='title'>{card.title}</h4>
                            </div>
                        </div>
                    ))}
                    <div className={`next-btn js-next-btn ${activeCard < 2 && activeCard >= 0 ? 'active-btn' : ''}`} onClick={() => handleCardClick(1)}><img className='next-arrow-shadow' src={arrowShadow} alt="prev-arrow" /><Arrow className="next-arrow" /></div>
                </div>
                <div className="card-content">
                    <div>{cards[activeCard].content}</div>
                </div>
            </div>
        </div>

    )
}

export default MainPillers