import React, { useContext, useEffect, useRef } from 'react';
import HyspecDriveDesc from './DriveToHyspec/HyspecDriveDesc';
import FAQSection from './DriveToHyspec/FAQSection';
import { gsap } from 'gsap';
import line4 from '../assets/sectio-4.svg';
import FeaturesSlider from './DriveToHyspec/FeaturesSlider';

import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";

const DriveToHyspec = () => {

  const containerRef = useRef('')
  const jsonObject = useContext(DataContext);
  const [findByKey] = useFindValueByKey(jsonObject)
  useEffect(() => {

    const container = containerRef.current
    const jsAnimations = Array.from(container.querySelectorAll('.js-animation'))
    gsap.set(jsAnimations, { opacity: 0 })
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.drive-to-hyspec',
        start: "top 102%",
        end: "4% 50%",
        performance: true,
        scrub: 1
      }
    })
    tl.to(jsAnimations, { opacity: 1, duration: 0.5, stagger: 0.5, delay: 0.5, ease: "power3.in" })
    return () => {
      tl.kill()
    };
  }, []);
  return (
    <div className='drive-to-hyspec section OneLinkTx' id='2' data-title={findByKey("DriveToHyspec.menu-title")} ref={containerRef}>

      <div className='first-container'>

        <div className='white-patch'>
          <img className="line4-svg" src={line4} alt="line svg" width={'100%'}></img>
        </div>
        <div className='text-info'>
          <h2 className='section-heading js-section-heading js-animation OneLinkTx' id='2' data-title={findByKey("DriveToHyspec.menu-title")}>{findByKey("DriveToHyspec.title")}</h2>
          <p className='para js-animation OneLinkTx'>{findByKey("DriveToHyspec.body.para-1")}</p>
        </div>
        <div className='info OneLinkTx'>
          <p>{findByKey("DriveToHyspec.body.para-2")}</p>
        </div>
      </div>
      <div className='second-container'>
        {/* <CarouselComp /> */}
        <FeaturesSlider />
        <HyspecDriveDesc />
      </div>
      <div className='third-container'>
        <FAQSection />
      </div>
    </div>
  )
}

export default DriveToHyspec