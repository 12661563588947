import React, { useRef, useEffect, useContext } from "react";
import Modal from './Modal';
// import { twoCansLogo, threeCansLogo } from "./assets";
// import { twoCans, twoCansLogo, threeCans, threeCansLogo } from "./assets";
import { ReactComponent as LineTopSVG } from '../assets/sec4top.svg';
import { ReactComponent as LineBottomSVG } from '../assets/sec4bottom.svg';
import TextAnimation from "./TextAnimation";

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {DataContext} from "./../utils/dataContext"
import { useFindValueByKey } from "./../utils/useFindByKey";
import { getLanguageCode } from "../utils/utils";


const HyspecPortfolio = () => {
    const lineTopRef = useRef('')
    const lineBottomRef = useRef('')
    const languageCode = getLanguageCode();

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    const handleOnPlay = () => { }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let tl
        const lineTopTl = gsap.timeline({
            scrollTrigger: {
                trigger: lineTopRef.current,
                start: '-20% center',
                end: '2% center',
                scrub: 2.5,
                performance: true,
                onEnter: () => {
                    tl = gsap.timeline()
                    playTimelineSequence(tl)
                },
                onLeaveBack: () => {
                    reverseTimeline(tl)
                },
                markers: false
            }
        })

        function playTimelineSequence(tl) {
            tl.to('#eKJYXIVa2kc37_to', {
                x: 986.70351,
                y: 4528.10278,
                duration: 2.5
            })
        }
        function reverseTimeline(tl) {
            tl.revert()
            tl.kill()
        }


        const lineBottomTl = gsap.timeline({
            scrollTrigger: {
                trigger: lineBottomRef.current,
                start: '-25% center',
                end: '-23% center',
                scrub: 2.5,
                markers: false
            }
        })
        lineBottomTl.to('#ePFptY2UQha21_to', {
            x: 825.277735,
            y: -1313.033478,
            duration: 2.5
        })
        return () => {
            if (lineTopTl) {
                lineTopTl.kill()
                lineBottomTl.kill()
            }
            if (tl) tl.kill()
        }
    }, []);

    return (
        <div className='hyspec-portfolio section OneLinkTx' id='4' data-title={findByKey("HyspecPortfolio.menu-title")}>
            <div className='section-bg'>
                <div className='container js-sec-4'>
                    <div className="hyspec-fit-first-section">

                        <div className="hyspec-portfolio-line-1">
                            <LineTopSVG className='line-top' ref={lineTopRef} />
                        </div>
                        <TextAnimation animationType="fadeIn" duration={1} delay={0.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 80%"} end={"top 80%"}>
                            <h2 className='section-heading js-section-heading OneLinkTx' id='4' data-title={findByKey("HyspecPortfolio.menu-title")}>
                                {findByKey("HyspecPortfolio.title")}
                            </h2>
                        </TextAnimation>
                        <div className="top-content" >
                            <TextAnimation scrollElement={".js-sec-4"} animationType="fadeIn" className="first" duration={1} delay={0.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"5% center"} end={"5% center"} >
                                <p dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.body.para-1') }} className="OneLinkTx"></p>
                            </TextAnimation>
                            <TextAnimation scrollElement={".js-sec-4"} animationType="fadeIn" className="second" duration={1} delay={0.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"8% center"} end={"8% center"}>
                                <p className="OneLinkTx">
                                    <br />{findByKey('HyspecPortfolio.body.para-2')}
                                </p>
                            </TextAnimation>
                            <ul className="underlist" >
                                <TextAnimation scrollElement={".js-sec-4"} animationType="fadeIn" duration={1} delay={0.7} ease={"power3.in"} initialState={{ opacity: 0 }} start={"9% center"} end={"9% center"}>
                                    <li className="sec-4-bulletList title-text-blue OneLinkTx">{findByKey('HyspecPortfolio.body.para-3.li-item-1')}</li><li className="sec-4-bulletList title-text-blue OneLinkTx">{findByKey('HyspecPortfolio.body.para-3.li-item-2')}</li>
                                </TextAnimation>

                            </ul>
                            <TextAnimation scrollElement={".js-sec-4"} animationType="fadeIn" className="glossary" id="glossary" duration={1} delay={0.9} ease={"power2.in"} initialState={{ opacity: 0 }} start={"9.2% center"} end={"9.2% center"}>
                                <div className="demo OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-4') }}>
                                </div>
                            </TextAnimation>

                        </div>
                    </div>
                    <div className="hyspec-fit-secound-section">
                        <div className="mid-content">
                            <div className="flex-1">
                                <div className="flex1-img-container" >

                                    <TextAnimation scrollElement={".flex-1"} animationType="slideInX" duration={1} delay={0.5} ease={"power3.inOut"} start={"-50% center"} end={"-30% center"} className='canes-wrapper' >
                                        <div id="canLeftImg">
                                            <img src={`./assets/${languageCode}/left-can.png`} alt="" width={'100%'} id="leftCan" />
                                            {/* <img src={threeCans} alt="" width={'100%'} id="leftCan" /> */}
                                        </div>

                                        <div id="canLeftImgLogo">
                                            <TextAnimation animationType="fadeIn" duration={1.5} delay={20.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 145%"} end={"bottom 85%"}>
                                                <img src={`./assets/${languageCode}/3_can.png`} alt="" width={'100%'} id="leftCanLogo" />
                                            </TextAnimation>
                                        </div>
                                    </TextAnimation>
                                </div>
                                <div className="canPara1-container">
                                    <TextAnimation scrollElement={".flex-1"} animationType="fadeIn" duration={1.5} delay={1.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"-50% center"} end={"-30% center"}>
                                        <div id="canPara1 OneLinkTx" >
                                            {findByKey('HyspecPortfolio.para-5')}
                                        </div>
                                    </TextAnimation>
                                </div>
                            </div>
                            <div className="flex-2">
                                <div className="canPara2-container">
                                    <TextAnimation animationType="fadeIn" duration={1.5} delay={1.5} ease={"power3.in"} start={"top 175%"} end={"bottom 85%"} initialState={{ opacity: 0 }}>
                                        <div id="canPara2 OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-6') }}>
                                        </div>
                                    </TextAnimation>
                                </div>
                                <div className="flex1-img-container"  >
                                    <TextAnimation scrollElement={".flex-2"} animationType="slideInXRight" duration={1} delay={1.5} ease={"power3.in"} start={"-80% center"} end={"-80% center"}>
                                        <div id="canRightImg">
                                            <img src={`./assets/${languageCode}/right-can.png`} alt="" width={'100%'} id="rightCan" />
                                            {/* <img src={twoCans} alt="" width={'100%'} id="rightCan" /> */}
                                        </div>
                                        <div id="canRightImgLogo">
                                            <TextAnimation animationType="fadeIn" duration={2.5} delay={20.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"-80% center"} end={"-80% center"}>
                                                <img src={`./assets/${languageCode}/2-can logo.png`} alt="" width={'100%'} id="rightCanLogo" />
                                            </TextAnimation>
                                        </div>
                                    </TextAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hyspec-fit-third-section">
                        <div className="bottom-content">
                            <div className="tab1">
                                <h4 className="OneLinkTx">{findByKey("HyspecPortfolio.para-7")}</h4>
                                <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-8') }}></p>
                                <div className='playerbtn-text one OneLinkTx'>
                                    {
                                        languageCode !== 'en-GB' 
                                        ? 
                                        <>
                                            <span className='hybrid-playerbtn'>
                                            <Modal id={7} textAnimation={false} isvideo={true} handleOnPlay={handleOnPlay} /></span>{findByKey("Common.keyPressInstruction.instruction-1")} <span><Modal id={7} textAnimation={false} isvideo={false} isClicked={true} /></span> {findByKey("Common.keyPressInstruction.instruction-3")}
                                        </>
                                        :
                                        <></>    
                                }
                                </div>
                            </div>
                            <div className="tab2">
                                <h4 className="OneLinkTx">{findByKey("HyspecPortfolio.para-9")}</h4>
                                <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-10') }}>
                                </p>
                                <div className='playerbtn-text two OneLinkTx'><span className='hybrid-playerbtn OneLinkTx'>
                                    <Modal id={8} textAnimation={false} isvideo={true} handleOnPlay={handleOnPlay} /></span>{findByKey("Common.keyPressInstruction.instruction-1")} <span><Modal id={8} textAnimation={false} isvideo={false} isClicked={true} /></span> {findByKey("Common.keyPressInstruction.instruction-3")}</div>
                            </div>
                        </div>
                        <div className="glossary">
                            <div className="demo">
                                <div className='includes-text'>
                                </div>
                                <p  className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-11') }}></p>
                            </div>
                            <p  className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-12') }}>
                            </p>
                            <p  className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-13') }}>
                            </p>
                            <p  className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey('HyspecPortfolio.para-14') }}>
                            </p>
                        </div>
                        <Modal id={2} />
                        <div className="line-bottom-container">
                            <LineBottomSVG className='line-bottom' ref={lineBottomRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HyspecPortfolio