import React, { useContext, useEffect, useRef, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import playerbtn from '../../assets/play_btn1.png'


import { colouredCar1, colouredCar2, colouredCar3 } from '../assets'
import Modal from "../Modal"

import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";

const CarouselSlider = ({ handleOnPlay }) => {
  const jsonObject = useContext(DataContext);
  const [findByKey] = useFindValueByKey(jsonObject)



  gsap.registerPlugin(ScrollTrigger)
  const MAX_SCALE = 3.4
  const content = [
    {
      title: <span className="title-text-blue OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.0.title") }}></span>,
      content: <div>
        <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.0.content.para-1") }}></p>
        <br />
        <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.0.content.para-2") }}></p>
        <p className='playerbtn-text OneLinkTx'><span className='hybrid-playerbtn'>
          <Modal id={4} textAnimation={false} isvideo={true} /></span>{findByKey("Common.keyPressInstruction.instruction-1")} <span><Modal id={4} textAnimation={false} isvideo={false} isClicked={true} /></span> {findByKey("Common.keyPressInstruction.instruction-3")}
        </p>

      </div>
    },
    {
      title: <span className="title-text-blue OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.1.title") }}></span>,
      content: <div>
        <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.1.content.para-1") }}></p>
        <p className='playerbtn-text OneLinkTx'>
          <span className='hybrid-playerbtn'>
            <Modal id={5} textAnimation={false} isvideo={true} handleOnPlay={handleOnPlay} />
          </span>{findByKey("Common.keyPressInstruction.instruction-1")}
          <span>
            <Modal id={5} textAnimation={false} isvideo={false} isClicked={true} />
          </span> {findByKey("Common.keyPressInstruction.instruction-3")}
        </p>


      </div>
    },
    {
      title: <span className="title-text-blue OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.2.title") }}></span>,
      content: <div>
        <p className="OneLinkTx" dangerouslySetInnerHTML={{ __html: findByKey("CarouselSlider.2.content.para-1") }}></p>
        <p className='playerbtn-text OneLinkTx'>
          <span className='hybrid-playerbtn'>
            <Modal id={6} textAnimation={false} isvideo={true} />
          </span>{findByKey("Common.keyPressInstruction.instruction-1")}
          <span>
            <Modal id={6} textAnimation={false} isvideo={false} isClicked={true} />
          </span> {findByKey("Common.keyPressInstruction.instruction-3")}
        </p>

      </div>
    }
  ]


  // function escapeHtmlTags(input) {
  //   return input.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  // }

  const cardsRef = useRef([])
  const [activeIndex, setActiveIndex] = useState(0)

  const handleAnimationOnScroll = (tl) => {

    const currentCar = cardsRef.current[0]
    const nextCar = cardsRef.current[1]
    const afterNextCar = cardsRef.current[2]
    //first step animation
    animateCarStep(tl, currentCar, nextCar, afterNextCar, "first", 0)
    //second step animation
    animateCarStep(tl, nextCar, afterNextCar, currentCar, "second", 119)
    // wait to timeline end
    tl.to(".js-hidden", { opacity: 0, duration: 2 })
    //animateCarStep(tl, afterNextCar, currentCar, nextCar, "third")
    //third step animation

  }
  const handleAnimationOnScrollRef = useRef(handleAnimationOnScroll);

  const initialXPosition = 250
  const moveActiveCarToLeft = 100 * 4
  const setInitialPosition = () => {
    gsap.set(cardsRef.current[0], {
      rotation: 0,
      x: `-${250}%`,
      y: "0%",
      scale: MAX_SCALE,
      onComplete: () => {
        setActiveIndex(0);
      }
    })
    gsap.set(cardsRef.current[1], {
      rotation: 90,
      x: "0%",
      y: "0%"
    })
    gsap.set(cardsRef.current[2], {
      rotation: 90,
      x: "60%",
      y: "0%"
    })
  };

  function animateCarStep(tl, currentCar, nextCar, afterNextCar, step, y) {

    tl.to(nextCar, {
      duration: 1, y: "+=100%", onEnter: () => {
        //setActiveIndex(-1);
      }
    }, step + "-carGo")
    tl.to(currentCar, {
      duration: 1,
      x: `-=${moveActiveCarToLeft}%`
    }, step + "-carGo")


    tl.to(currentCar, { opacity: 0, duration: 0 })

    tl.to(nextCar,
      {
        duration: 1,
        rotation: 0,
        x: `-${250}%`,
        y: `0%`,
        scale: MAX_SCALE,
        //transformOrigin: "top left",
        onComplete: () => {
          setActiveIndex((prevIndex) => {
            if (prevIndex === content.length - 1) return prevIndex
            return prevIndex + 1
          })
        },
        onReverseComplete: () => {
          setActiveIndex((prevIndex) => {
            if (prevIndex === 0) return 0
            return prevIndex - 1
          })
        },
      }
    )

    tl.set(currentCar, {
      x: `+=${initialXPosition + moveActiveCarToLeft + 100}%`,
      y: "0%",
      opacity: 1,
      duration: 0,
      rotation: 90,
      scale: 1
    })

    tl.to(afterNextCar, { x: "-=60%", duration: 1, ease: "" }, step + "-onPos")
    tl.to(currentCar, { x: "-=40%", duration: 1.2, ease: "linear" }, step + "-onPos")

  }

  const updateContent = () => {
    //console.log(activeIndex);
    const cars = cardsRef.current
    cars.forEach((car) => {
      if (car) {
        car.classList.remove('active')
        const scaleMatch = car.style.transform.match(/scale\(([^)]+)\)/);
        if (scaleMatch && scaleMatch[1]) {
          const [scaleX,] = scaleMatch[1].split(',').map(parseFloat);
          if (scaleX === MAX_SCALE) {
            car.classList.add('active')
          }
        }
      }
    })
    return <>
      <div className='type-heading'>
        <h3>{content[activeIndex].title}</h3>
      </div>
      <div className='body'>
        {content[activeIndex].content}
      </div>
    </>
  }

  useEffect(() => {

    //console.log("Use effect under")
    setInitialPosition()

    let tl = gsap.timeline({
      scrollTrigger: {
        start: "2% top",
        trigger: ".car-carousel-slider-wrapper",
        markers: false,
        end: "+=1500",
        scrub: 5,
        performance: true,
        anticipatePin: 1,
        onEnter: () => {
          //handleAnimationOnScroll(tl)
        },
        // onUpdate: ({ direction, progress }) => {
        //   const percent = Math.round(progress * 100);
        //   if (percent == 20 && direction == 1) {
        //     console.log("Progress", percent)
        //   }

        // }

      }
    })
    handleAnimationOnScrollRef.current(tl)
    return () => {
      if (tl) {
        tl.kill()
      }
    }
  }, [])



  return (

    <>
      <div className="car-carousel-slider-wrapper">
        <div className="car-carousel-slider">
          <img className={`car-slide ${activeIndex === 0 ? "active" : ""}`} src={colouredCar1} alt="" data-id="0" ref={(el) => cardsRef.current.push(el)} />
          <img className={`car-slide ${activeIndex === 1 ? "active" : ""}`} src={colouredCar2} alt="" data-id="1" ref={(el) => cardsRef.current.push(el)} />
          <img className={`car-slide ${activeIndex === 2 ? "active" : ""}`} src={colouredCar3} alt="" data-id="2" ref={(el) => cardsRef.current.push(el)} />
        </div>
        <div className={`content content-${activeIndex}`} >{updateContent()}</div>
      </div>
      <div className="js-hidden" style={{ display: "none" }}></div>

    </>
  )
}

export default CarouselSlider
