import React, { useContext, useState } from 'react';
import ShowModal from './ShowModal';
import keypoints from './Keypoints'
import TextAnimation from './TextAnimation';
import playerbtn from '../assets/play_btn1.png';
import { gsap } from 'gsap'
import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";
import { getLanguageCode } from '../utils/utils';


const Modal = ({ id, textAnimation = true ,isvideo=false,className, isClicked=false}) => {

  const [showModal, setShowModal] = useState(false);
  const [classesName, setClassesName] = useState('')

  const jsonObject = useContext(DataContext);
  const [findByKey] = useFindValueByKey(jsonObject)

  const closeModal = () => {
    const tl = gsap.timeline({})
    tl.to('.modal-container', {
      opacity: 0, duration: 1, onComplete: () => {
        setShowModal(false);
      }
    },"modal")
    tl.to('.modal-wrapper', {opacity: 0, duration: 1},"modal")
  }


  let modalContent = keypoints.map((keypoint, index) => {
    if (index === id) {
      return (
        <React.Fragment key={index}>
          {keypoint.content}
        </React.Fragment>
      );
    }
    return null
  })


  const mainModal = (
    <ShowModal className={classesName} closeModal={closeModal} id={id}>

      {modalContent}

    </ShowModal>
  )

  function handleKeyTakeawaysClick(e) {
    const parentElement = e.currentTarget.parentElement;
    parentElement.classList.add('clicked')
    setClassesName(keypoints[id].className)
    setShowModal(true)
    setTimeout(() => {
      parentElement.classList.remove('clicked')
    }, 200)

  }
  return (
    <>
      {
      textAnimation ?
        <TextAnimation className={`key-takeaways ${getLanguageCode() === 'zh-CN' && id === 0 ? 'lift-up' : ''}`} animationType="fadeIn" duration={0.3} delay={0.3} ease={"power3.in"} start={"top 100%"} end={"20% 90%"} >
          <button className={`modal-btn js-takeaways-${id} OneLinkTx`} onClick={handleKeyTakeawaysClick} style={{cursor:"pointer"}}>{findByKey("Keypoints.title")}</button>
        </TextAnimation>
        : 
        (isvideo ? <img src={playerbtn} width={'100%'} alt='' onClick={handleKeyTakeawaysClick} style={{cursor:"pointer"}} /> : 
        (isClicked ? <span className='play-button-text OneLinkTx' onClick={handleKeyTakeawaysClick}>&nbsp;{findByKey("Common.keyPressInstruction.instruction-2")}&nbsp;</span> :
        <div className='' ><button className={`modal-btn js-takeaways-${id} OneLinkTx`} onClick={handleKeyTakeawaysClick} style={{cursor:"pointer"}}>{findByKey("Keypoints.title")}</button></div>))
        }
      {showModal && mainModal}
    </>
  )
}

export default Modal