import React, { useEffect, useState, useRef, useContext } from 'react'
import curvedLineSvg from '../assets/last-divider.svg'
import pdfDownloadIconSvg from '../assets/pdf-icon-file-white.svg'
import globeIcon from '../assets/globe-icon.svg'
import { podcastIcon } from './assets'
import CustomModal from './CustomModal'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import hyspecPDF from '../assets/HYSPEC-knowledge-infographic-ENG.pdf'

import gsap from 'gsap';
import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";
import { getLanguageCode } from '../utils/utils'
gsap.registerPlugin(ScrollTrigger)

const UnlockHypsec = () => {

    const headingRef = useRef('')
    const castrol = useRef('')
    const castrolPara = useRef('')
    const dividerRef = useRef('')
    const containerRef = useRef('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)
    const languageCode = getLanguageCode();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target === document.body) {
                closeModal();
            }
        };
        if (isModalOpen) {
            document.addEventListener('click', handleOutsideClick);
            document.body.style.overflowY = 'hidden';
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.body.style.overflowY = 'scroll';
        };
    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOnPlay = () => {
        openModal()
    }

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = hyspecPDF;
        link.target = '_blank';
        link.download = 'HYSPEC-knowledge-infographic-ENG.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const container = containerRef.current
        const heading = headingRef.current
        const detailsContainer = Array.from(container.querySelectorAll('.js-details-container'))
        const jsAnimations = Array.from(container.querySelectorAll('.js-animation'))
        const jsHeadings = Array.from(container.querySelectorAll('.js-heading'))
        gsap.set(heading, { opacity: 0 })
        gsap.set(jsAnimations, { opacity: 0 })
        gsap.set(jsHeadings, { opacity: 0 })
        const timelines = [];
        const headerTl = gsap.timeline({
            scrollTrigger: {
                trigger: heading,
                start: "-30% 105%",
                end: "top 75%",
                performance: true,
                markers: false,
                scrub: 2
            }
        })
        headerTl.to(heading, { opacity: 1, duration: 0.5, delay: 0.5, ease: "power3.in" })

        detailsContainer.forEach((item) => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    start: "top 165%",
                    end: "bottom 106%",
                    performance: true,
                    scrub: 0.5
                }
            })
            timelines.push(tl)
        })

        jsHeadings.forEach((anim, index) => {
            const tl = timelines[index]
            tl.to(anim, { opacity: 1, duration: 0.5, delay: 1.5, ease: "power3.in" })
        })

        jsAnimations.forEach((anim, index) => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: jsAnimations[index],
                    start: "top 102%",
                    end: "bottom 102%",
                    performance: true,
                    markers: false,
                    scrub: 1
                }
            })
            tl.to(anim, { opacity: 1, duration: 0.5, delay: 2.5, ease: "power3.in", stagger: 0.8 })

        })

        return () => {
            headerTl.kill()
            timelines.forEach((tl) => tl.kill())
        };
    }, []);

    return (
        <div className='unlock-hypsec section OneLinkTx' id='6' data-title={findByKey('UnlockHyspec.menu-title')} ref={containerRef}>
            <div className='container'>
                <h2 ref={headingRef} className='section-heading js-section-heading js-section-heading-6 OneLinkTx' id='6' data-title={findByKey('UnlockHyspec.menu-title')} dangerouslySetInnerHTML={{ __html: findByKey('UnlockHyspec.title') }}>
                </h2>
                <div className='detials-container'>
                    <div className='details-inner-container js-details-container'>
                        <p className='details-title js-heading OneLinkTx'>
                            {findByKey('UnlockHyspec.body.0.title')}
                        </p>
                        <div id='details-body-1' className='js-animation'>
                            <p className='details-body OneLinkTx'>
                                {findByKey('UnlockHyspec.body.0.para')}
                            </p>
                            <span className='detials-divider'></span>
                        </div>

                    </div>
                    <div className='details-inner-container-one js-details-container'>
                        <p className='details-title js-heading OneLinkTx'>
                            {findByKey('UnlockHyspec.body.1.title')}
                        </p>
                        <div id='details-body-2' className='js-animation'>
                            <p className='details-body OneLinkTx'>
                                {findByKey('UnlockHyspec.body.1.para')}
                            </p>
                            <span className='detials-divider' ref={dividerRef}></span>
                        </div>

                    </div>
                    <div className='details-inner-container-two js-details-container'>
                        <p className='details-title js-heading OneLinkTx'>
                            {findByKey('UnlockHyspec.body.2.title')}
                        </p>
                        <div id='details-body-3' className='js-animation'>
                            <p className='details-body OneLinkTx'>
                                {findByKey('UnlockHyspec.body.2.para')}
                            </p>
                            <span className='detials-divider'></span>
                        </div>
                    </div>
                    <div className='details-inner-container-three js-details-container'>
                        <p className='details-title js-heading OneLinkTx'>
                            {findByKey('UnlockHyspec.body.3.title')}
                        </p>
                        <div id='details-body-4' className='js-animation'>
                            <p className='details-body OneLinkTx'>
                                {findByKey('UnlockHyspec.body.3.para')}
                            </p>
                            <span className='detials-divider'></span>
                        </div>
                    </div>
                </div>
                <div className='last-para'>
                    <span id='castrol' className='js-animation OneLinkTx' ref={castrol}>{findByKey("UnlockHyspec.word")}</span>
                    <p id='castrol-para' ref={castrolPara} className='js-animation OneLinkTx'>{findByKey("UnlockHyspec.para-5")}</p>
                </div>
                <div className='instruction'>
                    <div className='img js-animation' onClick={() => handleOnPlay()} >
                        <img src={podcastIcon} alt='' />
                    </div>
                    <div className='info js-animation' >
                        <p onClick={() => handleOnPlay()} className='OneLinkTx'>
                            {findByKey("UnlockHyspec.para-6")}
                        </p>

                        {isModalOpen && (
                            <CustomModal isOpen={true} onClose={closeModal}>
                                <iframe title="Podcast Player" style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/show/4mpfeBPFwpLvUvPXtauzWH?utm_source=generator" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />
                            </CustomModal>
                        )}
                    </div>
                </div>

                <div className='pdf-download-container js-animation' >
                    {
                        languageCode === 'en-US'
                        ?
                        <div>
                            <div className="extras" onClick={downloadPDF}>
                                <div className='pdf-icon'><img src={pdfDownloadIconSvg} alt='pdf-icon' /></div>
                                <div className='pdf-download-instruction OneLinkTx' id='download-instruction' >{findByKey("UnlockHyspec.para-7")}</div>
                            </div>
                            <div className="extras">
                                <div className='pdf-icon'><img src={globeIcon} alt='globe-icon' /></div>
                                <div className='pdf-download-instruction OneLinkTx'id='link' dangerouslySetInnerHTML={{ __html: findByKey("UnlockHyspec.para-8") }}></div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='pdf-icon'><img src={globeIcon} alt='globe-icon' /></div>
                            <div className='pdf-download-instruction OneLinkTx' id='link' dangerouslySetInnerHTML={{ __html: findByKey("UnlockHyspec.para-7") }}></div>
                        </>
                    }
                </div>

            </div>
            <img className='section-divider' src={curvedLineSvg} alt='' />
        </div>
    )
}

export default UnlockHypsec