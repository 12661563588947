import React, { useEffect, useState, useCallback } from 'react'
// import loading from './assets/loading.gif'
import navbarBg from './assets/navbar-bg-img.svg'
import heroBg from './assets/hero-bg.png';
import newPauseBtn from './assets/new-pause-btn.svg'
import switchHybridshSVG from './assets/line1.svg'
import grayCar from './assets/gray_car.svg'
import componentsBG from './assets/components.png';
import rightCurve from './assets/right-curve.svg';
import grayCar1 from './assets/car1.png';
import grayCar2 from './assets/car2.png';
import grayCar3 from './assets/car3.png';
import colouredCar1 from './assets/car1_coloured.svg';
import colouredCar2 from './assets/car2_coloured.svg';
import colouredCar3 from './assets/car3_coloured.svg';
// import video1 from './assets/1205_HYSPEC_HYBRID_MINI_MILD_ONLN_2023_Reignite_V08.mp4';
// import video2 from './assets/1205_HYSPEC_HYBRID_MINI_FULL_ONLN_2023_Reignite_V08.mp4';
// import video3 from './assets/1205_HYSPEC_HYBRID_MINI_PLUG-IN_ONLN2023_Reignite_V06.mp4';
import driveToHyspecBG from './assets/sec3BG.jpg';
import driveToHyspecSVG from './assets/sectio-4.svg';
import graph from './assets/diagram.svg'
// import contaminantsVideo from './assets/1189_CHS_103_2D_Translated_2023_v04.mp4';
// import intermittencyVideo from './assets/1189_Chs_101_2D_Translated_2023_ V04.mp4';
// import efficiencyVideo from './assets/1189_Hyspec_Multi_Reignite_Onln_V1.mp4';
import hypsecArrivalSVG from './assets/Line5.svg';
// import dropLet from './assets/dropLet.mp4';
import hyspecPortfolioTopSVG from './assets/sec4top.svg';
import twoCans from './assets/2-can.png'
import twoCansLogo from './assets/2-can logo.png'
import threeCans from './assets/3_can.png'
import threeCansLogo from './assets/3_can Logo.png'
import hyspecPortfolioBottomSVG from './assets/sec4bottom.svg';
import partneringExcellencef1SVG from './assets/line8.svg';
import partneringExcellenceRallySvg from './assets/line9.svg';
import f1Car from './assets/car-01-1-01.png';
import f1CarShadow from './assets/car-01-1-01-shadow.png';
import rallyCar from './assets/car-02-1-01.png';
import rallyCarSmoke from './assets/car-with-fog.png';
import unlockHysoecSVG from './assets/last-divider.svg';
import textureBg from './assets/texture-bg.svg';
import textureBg1 from './assets/texture-bg-1.svg';

import heroVideo from './assets/CASTROL_HYSPEC_BRAND_REIGNITE_SIMPLE_30SEC.mp4'
import video1 from './assets/1205_HYSPEC_HYBRID_MINI_MILD_ONLN_2023_Reignite_V08.mp4';
import video2 from './assets/1205_HYSPEC_HYBRID_MINI_FULL_ONLN_2023_Reignite_V08.mp4';
import video3 from './assets/1205_HYSPEC_HYBRID_MINI_PLUG-IN_ONLN2023_Reignite_V06.mp4';
import contaminantsVideo from './assets/1189_CHS_103_2D_Translated_2023_v04.mp4';
import intermittencyVideo from './assets/1189_Chs_101_2D_Translated_2023_V04.mp4';
import efficiencyVideo from './assets/1189_Hyspec_Multi_Reignite_Onln_V1.mp4';
import dropLet from './assets/dropLet.mp4';
import dropLetPoster from './assets/dropLet-poster.jpg'
import portfolioVideo1 from './assets/EDGE_HYBRID_FULL_ONLN_v4.mp4'
import portfolioVideo2 from './assets/MAGNATEC_Hybrid_Film_HIGHLANDER_GLOBAL.mp4'
import footerVideo from './assets/section_06_logo_animation.mp4'
import footerPoster from './assets/logo_animation-poster.jpg'

import heroVideoTH from './assets/th-TH/CASTROL_HYSPEC_BRAND_REIGNITE_SIMPLE_30SEC.mp4'
import video1TH from './assets/th-TH/1205_HYSPEC_HYBRID_MINI_MILD_ONLN_2023_Reignite_V08.mp4';
import video2TH from './assets/th-TH/1205_HYSPEC_HYBRID_MINI_FULL_ONLN_2023_Reignite_V08.mp4';
import video3TH from './assets/th-TH/1205_HYSPEC_HYBRID_MINI_PLUG-IN_ONLN2023_Reignite_V06.mp4';
import contaminantsVideoTH from './assets/th-TH/1189_CHS_103_2D_Translated_2023_v04.mp4';
import intermittencyVideoTH from './assets/th-TH/1189_Chs_101_2D_Translated_2023_V04.mp4';
import efficiencyVideoTH from './assets/th-TH/1189_Hyspec_Multi_Reignite_Onln_V1.mp4';
import portfolioVideo1TH from './assets/th-TH/EDGE_HYBRID_FULL_ONLN_v4.mp4'
import portfolioVideo2TH from './assets/th-TH/MAGNATEC_Hybrid_Film_HIGHLANDER_GLOBAL.mp4'

import App from './App';

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const PreloadAssets = () => {
    const [isLoading, setIsLoading] = useState(true)
    const _preloadAssets = useCallback(async () => {
        // URLs of the assets want to preload (e.g., images, SVG files, MP4 videos)
        const assetUrls = [
            navbarBg,
            textureBg,
            heroBg,
            newPauseBtn,
            textureBg1,
            switchHybridshSVG,
            grayCar,
            componentsBG,
            grayCar1,
            grayCar2,
            grayCar3,
            rightCurve,
            colouredCar1,
            colouredCar2,
            colouredCar3,
            driveToHyspecBG,
            driveToHyspecSVG,
            graph,
            dropLetPoster,
            hypsecArrivalSVG,
            hyspecPortfolioTopSVG,
            hyspecPortfolioBottomSVG,
            twoCans,
            twoCansLogo,
            threeCans,
            threeCansLogo,
            partneringExcellencef1SVG,
            f1Car,
            f1CarShadow,
            rallyCar,
            rallyCarSmoke,
            partneringExcellenceRallySvg,
            unlockHysoecSVG,
            footerPoster
        ]

        try {
            await Promise.allSettled(
                assetUrls.map((url) => {
                    return new Promise((resolve, reject) => {
                        if (url.endsWith('.svg')) {
                            // For SVG files, create an Image object to preload
                            const img = new Image()
                            img.src = url
                            img.onload = resolve
                            img.onerror = reject
                        } else if (url.endsWith('.mp4')) {
                            // For MP4 videos, create a video element to preload
                            const video = document.createElement('video')
                            video.src = url
                            video.preload = 'metadata' // Preload only metadata, not the entire video
                            video.onloadedmetadata = resolve
                            video.onerror = reject
                        } else {
                            // For other assets like images, you can preload them using fetch()
                            fetch(url)
                                .then((response) => {
                                    if (!response.ok) {
                                        reject(new Error('Failed to load asset'))
                                    } else {
                                        resolve()
                                    }
                                })
                                .catch(reject)
                        }
                    })
                })
            )
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.scrollTo(0, 0)
                showHideLoader(false)
                document.querySelector(".lds-spinner").style.display = "none"
                setIsLoading(false) 
            }, 5000)
            // document.querySelector(".lds-spinner").style.display = "none"
        } catch (error) {
            console.error('Error preloading assets:', error)
            showHideLoader(true)
            setIsLoading(true)
        }
    }, [])

    function initStickyAnimationSample() {

        gsap.to('.sticky-sample', {
            ease: 'linear',
            scrollTrigger: {
                start: 'top top',
                trigger: '.cache-video-container',
                pin: true,
                pinSpacing: false,
                anticipatePin: 1,
                performance: true,
                scrub: 5,
                end: "100px"
            }
        });
    }

    function showHideLoader(flag) {
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        initStickyAnimationSample()
        _preloadAssets()
    }, [_preloadAssets])

    return (
        <>
            <div className='cache-video-container' style={{ display: "none" }}>
            <div className='sticky-sample'></div>
                <video style={{ display: "none" }}>
                    <source src={heroVideo} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video1} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video2} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video3} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={contaminantsVideo} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={intermittencyVideo} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={efficiencyVideo} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={dropLet} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={portfolioVideo1} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={portfolioVideo2} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={footerVideo} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={heroVideoTH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video1TH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video2TH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={video3TH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={contaminantsVideoTH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={intermittencyVideoTH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={efficiencyVideoTH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={portfolioVideo1TH} type="video/mp4" />
                </video>
                <video style={{ display: "none" }}>
                    <source src={portfolioVideo2TH} type="video/mp4" />
                </video>
            </div>
            {isLoading ? (

                // <div className="lds-spinner">
                //     <div className="container">
                //         <img className="loading" src={loading} alt='loading' />
                //     </div>
                // </div>
                <></>

            ) : <App />}
        </>
    )
}

export default PreloadAssets
