import React, { useRef, useEffect, useContext } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";
import { getLanguageCode } from '../../utils/utils';

gsap.registerPlugin(ScrollTrigger)

const Standards = () => {

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)
    const langCode = getLanguageCode()

    const animatedTextRef = useRef('')
    const containerRef = useRef('')
    useEffect(() => {
        const text = findByKey("Standards.body.para-1")
        const container = containerRef.current;
        const textElement = document.querySelector(".typing-text");
        let charIndex = 0;
        let tick = null
        gsap.set(".js-footer", { opacity: 0 })
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: container,
                start: "-165% center",
                end: "-105% center",
                scrub: false,
                performance: true,
                markers: false,
                onEnter: () => {
                    clearInterval(tick)
                    const key = document.querySelector('.js-takeaways-0')
                    key.classList.add('visibility-hidden')
                    document.querySelector('.js-footer').style.opacity = 0
                    gsap.to(key, { opacity: 0, duration: 0 })
                    gsap.to(".js-footer", { opacity: 0, duration: 0 })
                    tick = setInterval(typeText, 30);
                },
                onLeaveBack: () => {
                    clearInterval(tick)
                    const key = document.querySelector('.js-takeaways-0')
                    key.classList.add('visibility-hidden')
                    document.querySelector('.js-footer').style.opacity = 0
                    gsap.to(key, { opacity: 0, duration: 0 })
                    gsap.to(".js-footer", { opacity: 0, duration: 0 })
                    textElement.innerHTML = ''
                }
            }
        })
        function typeText() {
            textElement.innerHTML = text.slice(0, charIndex) + (charIndex === text.length ? "<sup>2</sup>" : "  ");
            charIndex++;
            if (charIndex > text.length) {
                const tl = gsap.timeline({})
                const key = document.querySelector('.js-takeaways-0')
                key.classList.remove('visibility-hidden')
                tl.to(".js-footer", { opacity: 1, duration: 1 })
                tl.to(key, { opacity: 1, duration: 1 })
                clearInterval(tick)
                charIndex = 0;
            }
        }
        return () => {
            if (tl) {
                tl.kill()
            }
        };
    }, [langCode, findByKey]);
    return (
        <div className='standards-container' ref={containerRef} style={{ top : getLanguageCode() === 'zh-CN' ? '32vw' : ''}}>
            <div className='standards-desc'>
                <p id='animated-text' className='typing-text' ref={animatedTextRef}>
                </p>
                <p className='gray-text js-footer OneLinkTx' dangerouslySetInnerHTML={{ __html: findByKey('Standards.body.para-2') }}>
                </p>
            </div>
        </div>
    )
}

export default Standards