import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import videoSrc from '../assets/section_06_logo_animation.mp4'
import videoPoster from '../assets/logo_animation-poster.jpg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const VideoComponent = () => {
  const videoElementRef = useRef(null)
  const containerElementRef = useRef(null)

  useEffect(() => {
    const videoElement = videoElementRef.current
    const containerElement = containerElementRef.current
    let durations
    gsap.registerPlugin(ScrollTrigger)
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerElement,
        start: 'top center',
        end: 'top center',
        scrub: 4,
        performance: true,
        markers: false,
        onEnter: () => {
          videoElement.play().then(() => { console.log('play')}).catch(error => { console.error("Error playing video:", error); });
        },
        onLeaveBack: () => {
          videoElement.pause()
          videoElement.currentTime = 0
        }
      }
    })
    const loadMetadataHandler = () => {
      durations = videoElement.duration || durations || 5
    }
    videoElement.addEventListener('loadmetadata', loadMetadataHandler)
    return () => {
      tl.revert()
      tl.kill()
      videoElement.removeEventListener('loadmetadata', loadMetadataHandler)
    }
  }, [])
  return (
    <div className='video-component-container' ref={containerElementRef}>
      <motion.video
        ref={videoElementRef}
        src={videoSrc}
        poster={videoPoster}
        playsInline={true}
        preload="auto"
        muted="muted"
      />
    </div>
  );
};

export default VideoComponent;