import translationsEs  from '../locales/es-ES.json'
import translationsEn  from '../locales/en-US.json'
import translationsJp  from '../locales/ja-JP.json'
import translationsMy  from '../locales/ms-MY.json'
import translationsAr  from '../locales/ar-AE.json'
import translationsCz  from '../locales/cs-CZ.json'
import translationsDe  from '../locales/de-DE.json'
import translationsEl  from '../locales/el-GR.json'
import translationsFr  from '../locales/fr-FR.json'
import translationsHu  from '../locales/hu-HU.json'
import translationsId  from '../locales/id-ID.json'
import translationsIt  from '../locales/it-IT.json'
import translationsKr  from '../locales/ko-KR.json'
import translationsPl  from '../locales/pl-PL.json'
import translationsPt  from '../locales/pt-PT.json'
import translationsRo  from '../locales/ro-RO.json'
import translationsSk  from '../locales/sk-SK.json'
import translationsTh  from '../locales/th-TH.json'
import translationsTr  from '../locales/tr-TR.json'
import translationsVi  from '../locales/vi-VN.json'
import translationsZh  from '../locales/zh-CN.json'
import translationsGb  from '../locales/en-GB.json'
import translationsAu  from '../locales/en-AU.json'
import translationsNz  from '../locales/en-NZ.json'

export const toggleMenu = (flag) => {
    const menuElement = document.querySelector('.menu')
    if (menuElement) {
        menuElement.style.display = flag ? "block" : "none"
    }
}

const userAgent = navigator.userAgent.toLowerCase();
const mobileKeywords = ['android', 'webos', 'iphone', 'blackberry', 'iemobile', 'opera mini'];

export const checkIsMobile = () => {
    const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
    return isMobileDevice
};


export function isMac() {
    return /macintosh|mac os x/i.test(userAgent);
}

export function isIPhone() {
    return /iphone/i.test(userAgent);
}

export function isSafari() {
    return /safari/.test(userAgent) && !/chrome/.test(userAgent)
}

export function isMozilla(){
    return /mozilla/i.test(userAgent) && !/webkit/i.test(userAgent)
}

export function checkOrientation() {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    if (isPortrait) {
        return "portrait"
    } else {
        return "landscape"
    }
}

   
   export const getLanguageCode = () => {
       const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const langParam = params.get('lang');
   
    return langParam || "en-US";
};

export const getTranslations = (languageCode) => {
  switch (languageCode) {
    case 'ar-AE':
      return translationsAr;
    case 'cs-CZ':
      return translationsCz;
    case 'de-DE':
      return translationsDe;
    case 'el-GR':
      return translationsEl;
    case 'es-ES':
      return translationsEs;
    case 'fr-FR':
      return translationsFr;
    case 'hu-HU':
      return translationsHu;
    case 'id-ID':
      return translationsId;
    case 'it-IT':
      return translationsIt;
    case 'ja-JP':
      return translationsJp;
    case 'ko-KR':
      return translationsKr;
    case 'ms-MY':
      return translationsMy;
    case 'pl-PL':
      return translationsPl;
    case 'pt-PT':
      return translationsPt;
    case 'ro-RO':
      return translationsRo;
    case 'sk-SK':
      return translationsSk;
    case 'th-TH':
      return translationsTh;
    case 'tr-TR':
      return translationsTr;
    case 'vi-VN':
      return translationsVi;
    case 'zh-CN':
      return translationsZh;
    case 'en-GB':
      return translationsGb;
    case 'en-NZ':
      return translationsNz;
    case 'en-AU':
      return translationsAu;
    default:
      return translationsEn;
  }
  }
  

//    export const getLanguageCode = () => {
//     const code = navigator.language || navigator.userLanguage;
//     return code;
//    };

// export const fadeIn = (element, duration, delay, callback) => {
//     return gsap.fromTo(element, { opacity: 0 }, { opacity: 1, duration, delay, onComplete: callback })
// }