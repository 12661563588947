import React, { useContext } from 'react';
import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";

const HyspecDriveDesc = () => {
    
    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    return (
        <div className='drive-description'>
            <div className='para2'>
                <p className='drive-description-zero'>
                   {findByKey("HyspecDriveDesc.body.para-1")}
                </p>
                <p className='drive-description-one'>
                {findByKey("HyspecDriveDesc.body.para-2")}
                </p>
                <p className='drive-description-two'>
                {findByKey("HyspecDriveDesc.body.para-3")}
                </p>
                <p className='drive-description-three'>
                {findByKey("HyspecDriveDesc.body.para-4")}
                </p>
            </div>
        </div>
    )
}

export default HyspecDriveDesc