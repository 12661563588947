import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const TextAnimation = (props = {}) => {
  const { scrollElement, children, animationType, duration = 1, delay = 0.5, ease = "none", stagger = 0, className = '', initialState = {}, start = "top 100%", end = 'bottom 20%', scrub = 2, callbacks = {}, data = {}, markers = false } = props
  const scrollWrapperRef = useRef(null)

  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current
    const childrenElements = gsap.utils.toArray(scrollWrapper.children)
    let animation
    gsap.set(childrenElements, { ...initialState })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: scrollElement || scrollWrapper,
        start: start,
        end: end,
        scrub: scrub,
        performance: true,
        markers: markers,
      },
      data: data,
      ...callbacks
    })

    const triggerAnimation = () => {
      switch (animationType) {
        case 'fadeIn':
          animation = tl.fromTo(childrenElements, { opacity: 0, ...initialState }, { opacity: 1, duration: duration, delay: delay, ease: ease, stagger: stagger })
          break
        case 'fadeOut': case 'opacity-0':
          animation = tl.to(childrenElements, { opacity: 0, duration: duration, delay: delay, ease: ease, stagger: stagger })
          break
        case 'slideInX':
          animation = tl.fromTo(childrenElements, { x: '-12%', opacity: 0, ...initialState }, { x: '0%', duration: duration, opacity: 1, delay: delay, ease: ease, stagger: stagger })
          break
        case 'slideInXRight':
          animation = tl.fromTo(childrenElements, { x: '30%', opacity: 0, ...initialState }, { x: '0%', duration: duration, opacity: 1, delay: delay, ease: ease, stagger: stagger })
          break
        case 'slideOutX':
          animation = tl.to(childrenElements, { x: '100%', duration: duration, delay: delay, ease: ease, stagger: stagger })
          break
        case 'slideInY':
          animation = tl.fromTo(childrenElements, { y: '100%', opacity: 0, ...initialState }, { y: '0%', duration: duration, opacity: 1, delay: delay, ease: ease, stagger: stagger })
          break
        case 'slideOutY':
          animation = tl.fromTo(childrenElements, { y: '100%', opacity: 0, ...initialState }, { duration: duration, opacity: 1, delay: delay, ease: ease, stagger: stagger })
          break
        case 'opacity-1':
          animation = tl.to(childrenElements, { opacity: 1, duration, delay, ease, stagger: 1 })
          break
        default:
          break
      }
    }

    triggerAnimation()

    return () => {
      if (animation) {
        animation.kill()
      }
    }
  }, [scrollElement, animationType, duration, delay, ease, stagger, start, end, initialState, scrub, callbacks, data, markers])

  return <div className={className} ref={scrollWrapperRef}>{children}</div>

}

export default TextAnimation
