import React, { useState ,useEffect,useRef} from 'react'
import { gsap } from 'gsap'

const CustomModal = ({ isOpen, onClose,handleVideoEnd = (cb)=>{}, children }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen)
    const modelContentRef = useRef('')
    const closeModal = () => {
        gsap.fromTo('.js-modal-overlay',{opacity:1},{opacity:0,duration:1,onComplete:()=>{
            setIsModalOpen(false)
            onClose()
        }})
    }
    useEffect(() => {
        // const container = modelContentRef.current
        // const videos = container.querySelector('video') 
        // videos.addEventListener('onended',()=>{
        //    debugger;
        // })
     },[])

    return  (
        <div>
            <div className='modal-overlay js-modal-overlay' onClick={closeModal}></div>
            <div className={`js-modal-overlay cust-modal ${isModalOpen ? 'open' : ''}`}>
                <div className="modal-content" ref={modelContentRef}>
                    <button className="close-btn" onClick={closeModal}>
                        &times;
                    </button>
                    {children}
                </div>
            </div>
        </div>
    
    )
}

export default CustomModal