import React, { useCallback, useEffect, useState, useRef } from "react";
import { motion } from 'framer-motion';
import languages from "../locales/languageList.json";

const LanguageSelector = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownRef = useRef(null);

    const languageCodes = Object.keys(languages);
    const languageNames = Object.values(languages);

    const getLangFromQueryString = useCallback(() => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('lang') || languageCodes[0];
    }, [languageCodes]);

    const [selectedLanguage, setSelectedLanguage] = useState(getLangFromQueryString());

    const updateQueryString = (language) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('lang', language);
        window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    };
    
    const handleLanguageSelect = (langCode) => {
        setSelectedLanguage(langCode);
        updateQueryString(langCode);
        setIsOpen(false);
        window.location.reload();
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredLanguages = searchText ? languageNames.filter(lang => 
        lang.toLowerCase().includes(searchText.toLowerCase())
    ) : languageNames;

    let placeholderText = isOpen ? 'Type language here' : (languages[selectedLanguage] || 'Select your language');

    return (
        <motion.div
            className="language-dropdown OneLinkNoTx"
            ref={dropdownRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
        >
            <div className="custom-dropdown">
                <div className="search-input">
                    <input
                        type="text"
                        placeholder={placeholderText}
                        value={searchText} 
                        onChange={(e) => setSearchText(e.target.value)}
                        onClick={() => setIsOpen(true)}
                        className="language-input OneLinkNoTx"
                    />
                    <span className="icon" onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? '\u25B2' : '\u25BC'}
                    </span>
                </div>
                {isOpen && (
                    <div className="dropdown-menu">
                        <ul className="OneLinkNoTx">
                            {filteredLanguages.map((langName, index) => {
                                const langCode = languageCodes[languageNames.indexOf(langName)];
                                return (
                                    <li
                                        key={langCode}
                                        onClick={() => handleLanguageSelect(langCode)}
                                        className={selectedLanguage === langCode ? "active" : ""}
                                    >
                                        {langName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default LanguageSelector;
