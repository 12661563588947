import React from 'react'
import { video1, video2, video3, video1TH, video2TH, video3TH } from './assets'
import { portfolioVideo1, portfolioVideo2, portfolioVideo1TH, portfolioVideo2TH } from './assets'

import {DataContext} from "../utils/dataContext"
import { useFindValueByKey } from "../utils/useFindByKey";
import { useContext } from 'react'
import { getLanguageCode } from '../utils/utils';

const KeypointsModal = ({ items, isEscapedHTML = false }) => {

    const jsonObject = useContext(DataContext);
    const [findByKey] = useFindValueByKey(jsonObject)

    return (
        isEscapedHTML
            ?
            <span dangerouslySetInnerHTML={{ __html: findByKey(items) }} />
            :
            <>
                {findByKey(items)}
            </>
    )
}



const keypoints = [
    {
        id: 1,
        className: 'switchToHybrid-modal',
        content:
            <div className="keypoints-container" key='switchToHybrid'>
                <div className="title OneLinkTx">
                    <h3>
                        <KeypointsModal items={"Keypoints.title"} isEscapedHTML={true}/>
                    </h3>
                </div>
                <div className="content-grid">
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.0.keypoint-1"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="num1-info OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.0.para-1"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.0.keypoint-2"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="num2-info OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.0.para-2"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.0.keypoint-3"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="num3-info OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.0.para-3"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item-four">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.0.keypoint-4"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="num4-info OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.0.para-4"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="glossary">
                    <sup>1</sup>Per IHS Markit predictions, 2021.
                </div> */}
            </div>
    },
    {
        id: 2,
        className: 'hyspecIsArrived-modal',
        content:
            <div className="keypoints-container" key='hyspecIsArrived'>
                <div className="title OneLinkTx">
                    <h3>
                        <KeypointsModal items={"Keypoints.title"} isEscapedHTML={true}/>
                    </h3>
                </div>
                <div className="content-grid-section3">
                    <div className="grid-item keytake-away">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-1"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info1 OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.1.para-1"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-3"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info2 OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.1.para-3"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item-four">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-5"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info3 OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.1.para-5"} isEscapedHTML={true}/>
                            </p>
                            <ol>
                                <li className='OneLinkTx'><KeypointsModal items={"Keypoints.body.1.li-item-1"} isEscapedHTML={true}/></li>
                                <li className='OneLinkTx'><KeypointsModal items={"Keypoints.body.1.li-item-2"} isEscapedHTML={true}/></li>
                                <li className='OneLinkTx'><KeypointsModal items={"Keypoints.body.1.li-item-3"} isEscapedHTML={true}/></li>
                            </ol>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-2"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info4 OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.1.para-2"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-4"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info5 OneLinkTx">
                                <KeypointsModal items={"Keypoints.body.1.para-4"} isEscapedHTML={true}/>
                            </p>
                        </div>
                    </div>
                    <div className="grid-item-four">
                        <div className="number OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.1.keypoint-6"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section3-keytakeaway-info6 OneLinkTx" >
                                <KeypointsModal items={"Keypoints.body.1.para-6"} isEscapedHTML={true} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="glossary OneLinkTx">
                <KeypointsModal items={"Keypoints.body.1.para-7"} isEscapedHTML={true} />
                </div>
            </div>
    },
    {
        id: 3,
        className: 'hyspecPortfolio-modal',
        content:
            <div className="keypoints-container" key='hyspecPortfolio'>
                <div className="title OneLinkTx">
                    <h3>
                    <KeypointsModal items={"Keypoints.title"} />
                    </h3>
                </div>
                <div className="content-grid-section4">
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.2.keypoint-1"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section4-info1 OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.2.para-1"} isEscapedHTML={true} />
                                </p>
                                </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.2.keypoint-2"} />
                            </div>
                        <div className="content OneLinkTx"><p className="section4-info2">
                        <KeypointsModal items={"Keypoints.body.2.para-2"}  isEscapedHTML={true}/>
                            </p>
                            </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.2.keypoint-3"} />
                        </div>
                        <div className="content OneLinkTx"><p className="section4-info3">
                        <KeypointsModal items={"Keypoints.body.2.para-3"}  isEscapedHTML={true}/>
                            </p>
                            </div>
                    </div>
                    <div className="grid-item-four">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.2.keypoint-4"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section4-info4 OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.2.para-4"} isEscapedHTML={true}/>
                                </p>
                                </div>
                    </div>
                </div>
                <div className="glossary OneLinkTx">
                    <KeypointsModal items={"Keypoints.body.2.para-7"} isEscapedHTML={true} />
                </div>
            </div>
    },
    {
        id: 4,
        className: 'partneringExcellence-modal',
        content:
            <div className="keypoints-container" key='partneringExcellence'>
                <div className="title OneLinkTx">
                    <h3>
                    <KeypointsModal items={"Keypoints.title"} isEscapedHTML={true}/>
                    </h3>
                </div>
                <div className="content-grid-section5">
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.3.keypoint-1"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section5-info1 OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.3.para-1"} isEscapedHTML={true} />
                            </p>
                            </div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.3.keypoint-2"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section5-info2 OneLinkTx"> 
                            <KeypointsModal items={"Keypoints.body.3.para-2"} isEscapedHTML={true} />
                            </p></div>
                    </div>
                    <div className="grid-item">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.3.keypoint-3"} isEscapedHTML={true}/>
                        </div>
                        <div className="content OneLinkTx"><p className="section5-info3">
                        <KeypointsModal items={"Keypoints.body.3.para-3"}  isEscapedHTML={true}/>
                        </p>
                        </div>
                    </div>
                    <div className="grid-item-four">
                        <div className="number OneLinkTx">
                        <KeypointsModal items={"Keypoints.body.3.keypoint-4"} isEscapedHTML={true}/>
                        </div>
                        <div className="content">
                            <p className="section5-info4 OneLinkTx">
                            <KeypointsModal items={"Keypoints.body.3.para-4"} isEscapedHTML={true} />
                                </p>
                                </div>
                    </div>
                </div>
            </div>
    },
    {
        id: 5,
        className: 'mildHybrids-modal',
        content: <>
            <video className='video-player' controls height="90%" playsInline={true} width="100%" onEnded={() => { document.querySelector('.modal-wrapper').click() }}>
                <source src={getLanguageCode() === 'th-TH' ? `${video1TH}` : `${video1}` } type="video/mp4" />
            </video>
        </>
    },
    {
        id: 6,
        className: 'fullHybrids-modal',
        content: <>
            <video className='video-player' controls height="90%" width="100%" playsInline={true} onEnded={() => { document.querySelector('.modal-wrapper').click() }} >
                <source src={getLanguageCode() === 'th-TH' ? `${video2TH}` : `${video2}`} type="video/mp4" />
            </video>
        </>
    },
    {
        id: 7,
        className: 'plugInHybrids-modal',
        content: <>
            <video className='video-player' controls height="90%" width="100%" playsInline={true} onEnded={() => { document.querySelector('.modal-wrapper').click() }}>
                <source src={getLanguageCode() === 'th-TH' ? `${video3TH}` : `${video3}`} type="video/mp4" />
            </video>
        </>
    }, {
        id: 8,
        className: 'portfolioVideo1',
        content: <>
            <video className='video-player' controls height="90%" width="100%" playsInline={true} onEnded={() => { document.querySelector('.modal-wrapper').click() }}>
                <source src={getLanguageCode() === 'th-TH' ? `${portfolioVideo1TH}` : `${portfolioVideo1}`} type="video/mp4" />
            </video>
        </>
    }
    , {
        id: 9,
        className: 'portfolioVideo2',
        content: <>
            <video className='video-player' controls height="90%" width="100%" playsInline={true} onEnded={() => { document.querySelector('.modal-wrapper').click() }}>
                <source src={getLanguageCode() === 'th-TH' ? `${portfolioVideo2TH}` : `${portfolioVideo2}`} type="video/mp4" />
            </video>
        </>
    }

]
export default keypoints;