import React, { useRef, useEffect, useContext} from 'react';
import calenderImg from '../../assets/1-year-icon.svg';
import miniCar from '../../assets/10-vehicles-icon.svg';
import clockImg from '../../assets/7000-hours-icon.svg';
import dotsImg from '../../assets/2-billion-data-points-icon.svg';
import geolocation from '../../assets/300000-Km-icon.svg';
import urbanDriving from '../../assets/urban-driving-icon.svg';
import commuterDriving from '../../assets/commuter-driving-icon.svg';
import ruralDriving from '../../assets/rural-driving-icon.svg';
import handIcon from '../../assets/hand-icon.svg';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";

const FeaturesSlider = () => {


  const jsonObject = useContext(DataContext);
  const [findByKey] = useFindValueByKey(jsonObject)

  const containerRef = useRef('')
  gsap.registerPlugin(ScrollTrigger);

  const icons = [
    { src: calenderImg, alt: findByKey("FeatureSlider.alt-1"), desc: findByKey("FeatureSlider.desc-1") },
    { src: miniCar, alt: findByKey("FeatureSlider.alt-2"), desc: findByKey("FeatureSlider.desc-2") },
    { src: clockImg, alt: findByKey("FeatureSlider.alt-3"), desc: findByKey("FeatureSlider.desc-3") },
    { src: geolocation, alt: findByKey("FeatureSlider.alt-4"), desc: findByKey("FeatureSlider.desc-4") },
    { src: dotsImg, alt: findByKey("FeatureSlider.alt-5"), desc: findByKey("FeatureSlider.desc-5") },
    { src: ruralDriving, alt: findByKey("FeatureSlider.alt-6"), desc: findByKey("FeatureSlider.desc-6") },
    { src: commuterDriving, alt: findByKey("FeatureSlider.alt-7"), desc: findByKey("FeatureSlider.desc-7") },
    { src: urbanDriving, alt: findByKey("FeatureSlider.alt-8"), desc: findByKey("FeatureSlider.desc-8") },
    
  ];

  const iconsRef = useRef([]);
  useEffect(() => {

    gsap.set(".hover-instraction",{opacity:1})
    const tween = gsap.timeline({
      scrollTrigger: {
        start: "top center",
        end: "center center",
        trigger: ".slider-container",
        scrub: 1,
        performance: true,
        markers:false,
        onEnter: () => {
          gsap.fromTo(".hover-instraction", { opacity: 1 }, { opacity: 0, delay: 4, duration: 1 })
        }, onLeaveBack: () => {
          gsap.to(".hover-instraction", { opacity: 1 })
        },onEnterBack: () => {
          gsap.fromTo(".hover-instraction", { opacity: 1 }, { opacity: 0, delay:  3, duration: 1 })
        }
      }
    });
    return () => {
      if (tween) tween.kill()
    }
  }, [])
  return (
    <div className="slider-container">
      <div className="swiper_wrapper">
        <div className="swiper_container" ref={containerRef}>
              {icons.map((icon, index) => (
                <div 
                id={`item-${index}`} 
                className={`item item-${index} `}
                key={index} ref={(el) => (iconsRef.current[index] = el)}
                onMouseEnter={(e)=>{
                  Array.from(e.currentTarget.parentElement.parentElement.children).forEach(element => {
                    //console.log(element,'element');
                    element.classList.remove('prev');
                  });
                  //console.log(e.currentTarget.previousSibling,'sibling');
                  e.currentTarget.previousSibling?.classList.add('prev');
                  }} 
                  onMouseLeave={(e)=>{e.currentTarget.previousSibling?.classList.remove('prev');}}
                >
                  <img key={`img-${index}`} src={icon.src} alt={icon.alt}  />
                  <p key={`desc-${index}`} className="slide-desc OneLinkTx" id={`text-${index}`}>{icon.desc}</p>
                </div>
              ))}
            
        </div>
      </div>

       <div className='hover-instraction'>
        <img className='hover-hand-icon' src={handIcon} alt='' />
        <div className='hover-hand-text OneLinkTx'><h4>{findByKey("Common.hoverInstruction")}</h4></div>
      </div> 

    </div>
  )
}

export default FeaturesSlider