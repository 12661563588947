import React, { useState, useEffect } from 'react';
import { ReactComponent as CurveSVG } from '../../assets/right-curve.svg';
import { ReactComponent as ArrowSVG } from '../../assets/texture-bg.svg'
import CarouselSlider from './CarouselSlider'
import { getLanguageCode } from '../../utils/utils';

import { gsap } from 'gsap';

const HybridTypes = () => {

    const [, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const end = getLanguageCode() === 'zh-CN' ? 0 : 1600
        const container = document.querySelector('.hybrids-container')
        const pinContainer = document.querySelector('.hybrids-bg')
        const offsetHeight =  pinContainer.offsetHeight + end
        container.style.height = getLanguageCode() === 'zh-CN' ? 0 : `${offsetHeight}px`;
        const handleOutsideClick = (event) => {
            if (event.target === document.body) {
                closeModal();
            }
        };
        if (isModalOpen) {
            document.addEventListener('click', handleOutsideClick);
            document.body.style.overflowY = 'hidden';
        }
        const pinDelay = 0;
        let tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: '.hybrids-container',
                start: "top top",
                end: '+=' + end,
                pinType: "fixed",
                performance: true,
                pin: getLanguageCode() === 'zh-CN' ? false : true,
                scrub: getLanguageCode() === 'zh-CN' ? 0 : 2.5,
                anticipatePin: 1,
                pinSpacing: false,
                markers: false,
            }
        });
        tl1.scrollTrigger.refresh()
        const pinTimeout = setTimeout(() => {
            tl1.restart()
            tl1.scrollTrigger.refresh()
        }, pinDelay)


        // path animation
        let pathLocalTl
        const pathAnimationTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line2-svg',
                start: '20% center',
                end: '20% center',
                scrub: getLanguageCode() === 'zh-CN' ? 0 : 5,
                performance: true,
                markers: false,
                onEnter: () => {
                    pathLocalTl = gsap.timeline()
                    pathLocalTl.to('#eNVYz57i01X48_to', {
                        x: 678.46124,
                        y: 1392.211653,
                        duration: 4
                    })
                },
                onLeaveBack: () => {
                    if (pathLocalTl) pathLocalTl.revert()
                }
            }
        })

        let tl2
        const arrowTL2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.hybrids-bg',
                start: '-10% center',
                end: '5% center',
                performance: true,
                scrub: 5,
                markers: false,
                onEnter: () => {
                    tl2 = gsap.timeline({})
                    tl2.to('.js-arrow-2 #e8utjXfyGxH5_to', {
                        x: 697.72699,
                        y: 558.94696,
                        delay: 0.1,
                        ease: "Sine.easeOut",
                        duration: 2
                    })
                },
                onLeaveBack: () => {
                    if (tl2) {
                        tl2.revert()
                    }
                }
            }
        })

        return () => {
            if (tl1) {
                tl1.kill()
                tl1.revert()
            }
            if (arrowTL2) arrowTL2.kill()
            if (pathAnimationTl) pathAnimationTl.kill()
            if (arrowTL2) arrowTL2.kill()
            clearTimeout(pinTimeout)
            document.removeEventListener('click', handleOutsideClick);
            document.body.style.overflowY = 'scroll';
        };



    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOnPlay = (e, index) => {
        setActiveIndex(index);
        openModal()
    }
    return (
        
        <>
            <div className='hybrids-container'>
                <div className='hybrids-bg'>
                    { getLanguageCode() !== 'zh-CN' ? <ArrowSVG className='texture-bg js-arrow-2' />:'' }
                    <CurveSVG className='line-bg js-line2-svg' style={{ top: getLanguageCode() === 'zh-CN' ? '-55vw' : '-20vw' , left: getLanguageCode() === 'zh-CN' ? '40vw' : '28vw'}}/>
                    { getLanguageCode() !== 'zh-CN' ? <CarouselSlider handleOnPlay={handleOnPlay} />:'' }
                </div>
            </div>
        </>
    )
}

export default HybridTypes