import React, { useRef, useEffect, useContext } from 'react'
import { ReactComponent as EngineSVG } from '../../assets/engine_chain.svg'
import { ReactComponent as SparkSVG } from '../../assets/spark_chain.svg'
import TextAnimation from '../TextAnimation';

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {DataContext} from "../../utils/dataContext"
import { useFindValueByKey } from "../../utils/useFindByKey";


const Electrification = () => {
  gsap.registerPlugin(ScrollTrigger)
  const sparkEngineRef = useRef('')
  const engineRef = useRef('')
  const sparkRef = useRef('')
  const containerRef = useRef('')


  const jsonObject = useContext(DataContext);
  const [findByKey] = useFindValueByKey(jsonObject)

  useEffect(() => {
    let tl
    const mtl = gsap.timeline({
      scrollTrigger: {
        trigger: '.electrification',
        start: "45% center",
        end: "50% center",
        scrub: 5,
        performance: true,
        markers: false,
        // onEnter: () => {
        //   tl = gsap.timeline()
        //   playTimelineSequence(tl)
        // },
        // onLeaveBack: () => {
        //   reverseTimeline(tl)
        // }
      }
    })
    playTimelineSequence(mtl)


    function playTimelineSequence(tl) {
      // tl.to('.js-fake-ele', { opacity: 0, duration: 0.1, delay: 0 })
      // tl.to(".js-anim-text", { opacity: 1, duration: 0.1, delay: 0 }, "seq_1")
      tl.to(".js-engine-spark", {
        transform: 'translate(0, 0)', opacity: 1, stagger: 0, duration: 1.5, delay: 0.5, ease: "power3.in"
      }, "seq_1")
      tl.to(".js-mask", { opacity: 1, stagger: 0, duration: 0.1, delay: 0, ease: "power3.in" })
      tl.to(".js-spark-engine-title", { opacity: 1, stagger: 0, delay: 0, duration: 0.1, ease: "liner" })
      // tl.to('.js-fake-ele', { opacity: 0, duration: 0.1, delay: 0 })
    }

    // function reverseTimeline() {
    //   tl.revert()
    //   tl.kill()
    // }

    return () => {
      if (mtl) mtl.revert()
      if (tl) tl.kill()
    };

  }, []);

  return (
    <>
      <TextAnimation scrollElement=".js-anim-text" animationType="fadeIn" duration={0.3} delay={0.1} ease={"power3.in"} initialState={{ opacity: 0 }} start={"-15% center"} end={"-15% center"}  >
        <p className='description js-anim-text OneLinkTx' style={{ opacity: 0 }} >{findByKey("Electrification.body.para-1")}</p>
      </TextAnimation>
      {/* <p className='description js-anim-text' style={{ opacity: 0 }} >Hybrids represent the link between past designs and future philosophies facing a unified goal:</p> */}
      <div className='electrfication-section' ref={containerRef}>
        {/* <DrawSVGOnScroll> <EngineSVG /></DrawSVGOnScroll> */}
        <div className='engine-and-spark' ref={sparkEngineRef}>

          <div className='engine-chain js-engine-spark' style={{ opacity: 0 }} ref={engineRef} >
            <EngineSVG />
          </div>
          <div className='spark-chain js-engine-spark' style={{ opacity: 0 }} ref={sparkRef} ><SparkSVG /></div>
        </div>
        {/* <img src={engineImg} alt='Engine and spark' /> */}


        {/* <TextAnimation animationType="fadeIn" duration={1} delay={0.5} stagger={0.5} ease={"power3.in"} initialState={{ opacity: 0 }} start={"top 125%"} end={"bottom 60%"}> */}
        {/* </TextAnimation> */}
        {/* <TextAnimation animationType="fadeIn" duration={1} delay={250} ease={"power1.in"} initialState={{ opacity: 0 }} start={"top center"} end={"bottom 45%"}> */}

        {/* </TextAnimation>  */}
      </div>

      <p className='title js-spark-engine-title title-text-blue OneLinkTx' style={{ opacity: 0 }} >{findByKey("Electrification.body.para-2")}</p>

    </>
  )
}

export default Electrification